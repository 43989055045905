import React, { useEffect, useState, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-ads";
import "videojs-ima";
import { noOfAds } from "../../utils/generateTokenHandler";

const VAST_ADS = [
  "https://vast.yomeno.xyz/vast?spot_id=1435772",
  "https://vast.yomeno.xyz/vast?spot_id=1435771",
  "https://vast.yomeno.xyz/vast?spot_id=1435620",
  "https://vast.yomeno.xyz/vast?spot_id=1435752",
  "https://vast.yomeno.xyz/vast?spot_id=1435772",
  "https://vast.yomeno.xyz/vast?spot_id=1435771",
  "https://vast.yomeno.xyz/vast?spot_id=1435620",
  "https://vast.yomeno.xyz/vast?spot_id=1435752",
  "https://vast.yomeno.xyz/vast?spot_id=1435753",
  "https://vast.yomeno.xyz/vast?spot_id=1435754",
  // "https://vast.yomeno.xyz/vast?spot_id=1435755",
];

const VideoAdPlayerv1 = ({
  onAdsComplete,
  handlePayViaAds,
  src,
  index,
  adPosition,
  // AdPosition,
}) => {
  const videoRef = useRef(null);
  const [playerInstance, setPlayerInstance] = useState(null);

  const [adIndex, setAdIndex] = useState(
    // sessionStorage.getItem("ad-count")
    0
  );

  const [adErr, setAdError] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    console.log("Video player is initializing...");

    if (window.playerhell) {
      console.log("window.player: ", window.playerhell);
      console.log("playerInstance: ", playerInstance);
      console.log("Player already exists...");

      return;
    }

    const player = videojs(videoElement, {
      controls: true,
      autoplay: false,
      preload: "auto",
    });
    const randomNumber = Math.floor(Math.random() * VAST_ADS.length);
    player.ima({
      adTagUrl: VAST_ADS[randomNumber] || VAST_ADS[0],
      adsEnabled: true,
    });

    window.playerhell = player;
    setPlayerInstance(player);

    player.ready(() => {
      console.log("Player is ready, initializing ads...");

      if (
        player.ima &&
        typeof player.ima.initializeAdDisplayContainer === "function"
      ) {
        player.ima.initializeAdDisplayContainer();
        console.log("Ad display container initialized.");
      } else {
        console.warn("IMA plugin not initialized properly.");
      }

      player.ima.requestAds();
    });
    player.on("adend", () => {
      handlePayViaAds();
      let adCount = Number(sessionStorage.getItem("ad-count"));

      sessionStorage.setItem(adPosition, true);
      onAdsComplete();
    });

    // Handle Ad Errors More Gracefully
    player.on("adserror", () => {
      console.error("Ad error occurred.");
      setAdError((prevErr) => prevErr + 1);

      if (adErr > 50) {
        console.log("Too many ad errors, stopping ads.");
        onAdsComplete();
        return;
      }

      // Pick a new ad in case the current one failed
      const randomNumber = Math.floor(Math.random() * VAST_ADS.length);
      player.ima.changeAdTag(VAST_ADS[randomNumber]);

      // if (!window.playerhell.ima.isInAdMode()) {
      player.ima.initializeAdDisplayContainer();
      player.ima.requestAds();
      // }
    });

    return () => {
      console.log(process.env.NODE_ENV);
      player.dispose();
      window.playerhell = null;
      console.log("cleaned up");
    };
  }, []);

  useEffect(() => {}, []);
  return (
    <div>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        style={{
          objectFit: "contain",
          aspectRatio: 2 / 1,
          width: "100%",
          height: "80vh",
        }}
        controls
        id="video-ads-player"
      >
        <source src={src} type="video/mp4" />
      </video>
      <div>
        Ad : {index + 1} of {10}
      </div>
    </div>
  );
};

export default VideoAdPlayerv1;
