import React, { useRef, useState } from "react";
import styles from "./DetailsScreenBanner.module.css";
import { useTranslation } from "react-i18next";
import { durationConverter } from "../../utils/durationConverter";
import ResumePlay from "../resumePlay/ResumePlay";
import { useNavigate } from "react-router-dom";
import { handleClickTicket } from "../../utils/handleClickTicket";
import API from "../../utils/apiService";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ResumePlayViaAds from "../resumePlayViaAds/ResumePlayViaAds";
import { getToast } from "../../utils/getToast";

const DetailsScreenBanner = ({
  title,
  synopsis,
  cbfc_Rating,
  PricesIndividuals,
  PricesGroups,
  trailers,
  isAvod,
  backgroundImage,
  duration,
  genre,
  paymentDetails,
  isSeries,
  seasonNumber,
  episodeNumber,
  video_id,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let favourites = JSON.parse(localStorage.getItem("favourites")) || [];
  const [isFav, setIsFav] = useState(favourites.includes(video_id));
  const [fevLoading, setFevLoading] = useState(false);
  const [currentTrailer, setCurrentTrailer] = useState(0);
  const videoRef = useRef();
  const [show, setShow] = useState(false);

  const handlePurchase = () => {
    handleClickTicket({
      isSeries,
      seasonNumber,
      episodeNumber: null,
      navigate,
    });
  };

  // handle add favourite
  const handleAddFavourite = async () => {
    setFevLoading(true);
    try {
      let data = {
        videoId: video_id,
      };

      let response = await API.post("/favourite/add", data);
      if (response?.status) {
        favourites.push(video_id);
        getToast("Added to favourites", "top-right", "success");
        localStorage.setItem("favourites", JSON.stringify(favourites));
        setIsFav(true);
      }
    } catch (error) {
    } finally {
      setFevLoading(false);
    }
  };

  const handleDeleteFavourite = async () => {
    setFevLoading(true);
    try {
      let data = {
        videoId: video_id,
      };

      let response = await API.delete("/favourite/remove", data);
      if (response?.status) {
        const newfav = favourites?.filter((el) => el != video_id);
        getToast("Removed from favourites", "top-right", "success");

        localStorage.setItem("favourites", JSON.stringify(newfav));
        setIsFav(false);
      }
    } catch (error) {
    } finally {
      setFevLoading(false);
    }
  };

  const handleTrailerOpen = () => {
    setShow(true);
    handleTrailerAndVideoClick();
    if (videoRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoRef.current.requestFullscreen();
      }
    }
  };

  const handleTrailerAndVideoClick = async () => {
    try {
      let data;
      if (isSeries) {
        data = {
          video_id,
          trailer: true,
          seasonNumber: seasonNumber,
          banner: false,
        };
      } else {
        data = {
          video_id,
          trailer: true,
          banner: false,
        };
      }
      API.post("videoClickOrTrailerPlayed", data);
    } catch (error) {}
  };

  const handleClose = () => {
    setCurrentTrailer(0);
    setShow(false);
  };

  return (
    <div
      className={styles.banner_containr}
      style={{
        background: `linear-gradient(25deg,rgb(20, 20, 20) 25%, rgba(20, 20, 20, 0) 100%),
      url(${encodeURI(backgroundImage)}) 
      center / cover no-repeat`,
      }}
    >
      <div className={styles.banner_containr_box}>
        <div className={styles.banner_containr_box_title}>
          <h3>{title || ""}</h3>
        </div>
        <div className={styles.banner_containr_box_body}>
          <div className={styles.banner_containr_box_body_tags}>
            <label>{durationConverter(duration)}</label>{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="4"
              viewBox="0 0 4 4"
              fill="none"
            >
              <circle cx="2" cy="2" r="2" fill="white" />
            </svg>
            <label className={styles.banner_containr_box_body_tags_genre}>
              {genre?.join("/ ")}
            </label>
            <div className={styles.banner_containr_box_body_tags_cbfc}>
              <label>{cbfc_Rating}</label>
            </div>
            <button
              disabled={fevLoading}
              onClick={isFav ? handleDeleteFavourite : handleAddFavourite}
              className={styles.banner_containr_box_body_tags_add_watchList}
            >
              {isFav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#22bb33"
                  class="bi bi-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="white"
                  class="bi bi-heart"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                </svg>
              )}
            </button>
          </div>
          <div className={styles.banner_containr_box_body_info}>
            <p>{synopsis || ""}</p>
          </div>
        </div>
        <div className={styles.banner_containr_box_btns}>
          <div className={styles.tickets}>
            <h5>{t("Buy Tickets")}</h5>
            <div className={styles.tickets_body}>
              <OverlayTrigger
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-${"bottom"}`}>
                    {t(
                      "The Individuals feature is available only on the app's. To access it, please download the app from the Google Play Store."
                    )}
                  </Tooltip>
                }
              >
                <button
                  disabled
                  className={styles.tickets_body_left + ` ${styles.disabled}`}
                >
                  <div className={styles.tickets_body_left_header}>
                    <label>{t("For Individuals")}</label>
                  </div>
                  <div>
                    <label>
                      <span>Rs {PricesIndividuals}</span>/per person
                    </label>
                  </div>
                </button>
              </OverlayTrigger>
              <button
                onClick={handlePurchase}
                disabled={paymentDetails}
                className={
                  styles.tickets_body_right +
                  ` ${paymentDetails ? styles.disabled : ""}`
                }
              >
                <div className={styles.tickets_body_right_header}>
                  <label>{t("For Groups")}</label>
                </div>
                <div>
                  <label>
                    <span>Rs {PricesGroups}</span>/per person
                  </label>
                </div>
              </button>
            </div>
          </div>

          <div className={styles.banner_containr_box_btns_options}>
            <button onClick={handleTrailerOpen}>{t("Watch Trailer")}</button>
            {paymentDetails ? (
              <ResumePlay {...paymentDetails} isSeries={isSeries} />
            ) : (
              <ResumePlayViaAds
                isSeries={isSeries}
                seasonNumber={seasonNumber}
                episodeNumber={episodeNumber}
                video_id={video_id}
              />
            )}
          </div>
        </div>
        <div className={styles.banner_containr_box_footer}>
          <p>
            * {t("The purchase include 5 days to start watching this video.")}
          </p>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className={styles.trailers_modal}
        keyboard={false}
        centered
      >
        <Modal.Body className={styles.modal_body}>
          <div className={styles.video_container}>
            <video
              controlsList="nodownload"
              className={styles.video_container_video}
              ref={videoRef}
              src={trailers?.[currentTrailer]?.file}
              controls
            ></video>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={`${styles.watch_trailer_btn} ${styles.trailers_btns}`}
        >
          <button
            onClick={handleClose}
            style={{ display: currentTrailer == 0 ? "none" : "" }}
            className={styles.trailers_btns_close}
          >
            {currentTrailer != 0 ? t("Close") : ""}
          </button>

          <button
            onClick={
              currentTrailer == 0
                ? handleClose
                : () => setCurrentTrailer(currentTrailer - 1)
            }
          >
            {currentTrailer == 0 ? t("Close") : t("Previous")}
          </button>

          <button
            disabled={currentTrailer == trailers?.length - 1}
            className={
              currentTrailer == trailers?.length - 1 ? styles.disabled_btn : ""
            }
            onClick={() => setCurrentTrailer(currentTrailer + 1)}
          >
            {t("Next")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DetailsScreenBanner;
