import React, { useEffect, useState } from "react";
import styles from "./GroupPurchasePayment.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../utils/apiService";
import { getToast } from "../../utils/getToast";
import { v4 as uuidv4 } from "uuid";
import { getDeviceType } from "../../utils/getDeviceType";
import { groupPurchaseDetails } from "../../utils/groupPurchaseDetails";
import GroupPurchasePaymentSkeleton from "../../components/Skeletons/GroupPurchasePaymentSkeleton";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getWalletSucc } from "../../redux/dataReducer/action";

const GroupPurchasePayment = () => {
  const { walletBalance } = useSelector((store) => store.dataReducer);
  const { t } = useTranslation();
  const { video_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [seasonNumber, setSeasonNumber] = useState(
    parseInt(sessionStorage.getItem("seasonNumber")) || 1
  );
  const [episodeNumber, setEpisodeNumber] = useState(
    parseInt(sessionStorage.getItem("episodeNumber")) || null
  );
  const [isSeries, setIsSeries] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);
  const [devicePricing, setDevicePricing] = useState(null);
  const [currentSeason, setCurrentSeason] = useState({});
  const [walletPaymentLoading, setWalletPaymentLoading] = useState(false);
  const [upiPaymentLoading, setUpiPaymentLoading] = useState(false);
  const [price, setPrice] = useState(null);

  // handle paye for watchparty via wallet
  const handlePayWatchPartyFromWallet = async () => {
    console.log("calll.....");

    if (!price || price <= 0 || walletPaymentLoading) {
      return;
    }

    if (price > walletBalance) {
      getToast("insufficient wallet balance.", "top-right", "warn");
      return;
    }

    console.log("calll.....2");
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    setWalletPaymentLoading(true);
    handleAddViewer(viewerShipId, viewerId);
    try {
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: price,
        videoId: video_id,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: isSeries ? "watchPartySeason" : "watchParty",
          amount: price,
          payDevice: getDeviceType(),
          deviceType: getDeviceType(),
          videoId: video_id,
          isSeries: isSeries,
          // episode: null,
          // seasonNumber: null,
          episodeNumber: isSeries && episodeNumber ? episodeNumber : null,
          seasonNumber: seasonNumber && isSeries ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);
      let response = await API.post("pay/wallet", data);
      if (response?.status) {
        if (response?.status && response?.response_code == 200) {
          getToast(response?.message, "top-right", "success");

          sessionStorage.setItem("videoDetails", video_id);
          sessionStorage.setItem("viewerShipId", viewerShipId);
          localStorage.setItem("playFromPaymentHandler", true);
          if (isSeries) {
            sessionStorage.setItem("seasonNumber", seasonNumber);
            sessionStorage.setItem("episodeNumber", episodeNumber);
          }
          localStorage.setItem("playWithAds", false);
          localStorage.setItem(
            "deviceDetails",
            JSON.stringify({
              deviceType: getDeviceType(),
            })
          );
          // sessionStorage.setItem("watchType", "watchParty");
          sessionStorage.setItem(
            "watchType",
            isSeries ? "watchPartySeason" : "watchParty"
          );

          setWalletPaymentLoading(false);
          navigate("/paymentSuccess", { replace: true });
        }
      }
    } catch (error) {
      console.log("wallet payment process error", error);
    } finally {
      setWalletPaymentLoading(false);
    }
  };

  // get wallet Detials
  const getWalletDetails = async () => {
    try {
      let response = await API.get("walletDetails");
      if (response?.status) {
        dispatch(getWalletSucc(response?.data?.accountBalance));
      }
    } catch (error) {}
  };

  // handle pay for watchparty
  const handlePayWatchParty = async () => {
    if (!price || price <= 0 || upiPaymentLoading) {
      return;
    }
    setUpiPaymentLoading(true);
    const viewerId = localStorage.getItem("viewerId");
    const viewerShipId = uuidv4();
    handleAddViewer(viewerShipId, viewerId);

    try {
      const data = {
        viewerShipId: viewerShipId,
        viewerIds: [viewerId],
        amount: price,
        videoId: video_id,
        transactionDate: new Date(),
        videoDetails: {
          viewType: 1,
          priceType: isSeries ? "watchPartySeason" : "watchParty",
          amount: price,
          payDevice: getDeviceType(),
          deviceType: getDeviceType(),
          videoId: video_id,
          isSeries: isSeries,
          // episode: null,
          // seasonNumber: null,
          episodeNumber: isSeries && episodeNumber ? episodeNumber : null,
          seasonNumber: seasonNumber && isSeries ? seasonNumber : null,
          numberOfViewers: 1,
        },
      };
      console.log("data: ", data);
      let response = await API.post("pay_page", data);
      if (response?.status) {
        if (response?.status && response?.response_code == 200) {
          getToast(response?.message, "top-right", "success");
          const reddirectUrl =
            response?.data["data"]["instrumentResponse"]["redirectInfo"]["url"];

          sessionStorage.setItem(
            "transactionId",
            response?.data["data"]["merchantTransactionId"]
          );

          console.log("reddirectUrl: ", reddirectUrl);
          sessionStorage.setItem("videoDetails", video_id);
          sessionStorage.setItem("viewerShipId", viewerShipId);
          localStorage.setItem("playFromPaymentHandler", true);
          if (isSeries) {
            sessionStorage.setItem("seasonNumber", seasonNumber);
            sessionStorage.setItem("episodeNumber", episodeNumber);
          }
          localStorage.setItem("playWithAds", false);
          localStorage.setItem(
            "deviceDetails",
            JSON.stringify({
              deviceType: getDeviceType(),
            })
          );
          // sessionStorage.setItem("watchType", "watchParty");
          sessionStorage.setItem(
            "watchType",
            isSeries ? "watchPartySeason" : "watchParty"
          );
          setUpiPaymentLoading(false);
          window.location.replace = reddirectUrl;
          // navigate("/paymentPending");
        }
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setUpiPaymentLoading(false);
    }
  };

  const getVideoDetailsById = async () => {
    setLoading(true);
    try {
      let response = await API.get(`getVideoDetailsById/${video_id}`);

      if (response?.status) {
        let priceType;
        if (response?.data?.isSeries) {
          if (seasonNumber && episodeNumber) {
            priceType = "watchPartyPerEpisode";
          } else {
            priceType = "watchPartySeason";
          }
        } else {
          priceType = "watchParty";
        }

        let details = groupPurchaseDetails(
          response?.data?.isSeries,
          seasonNumber,
          episodeNumber,
          response?.data,
          priceType,
          getDeviceType()
        );
        console.log("data", details);
        setIsSeries(response?.data?.isSeries);
        setVideoDetails(details);
        setPrice(details?.Prices);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWalletDetails();
    getVideoDetailsById();
  }, []);

  // for add viewer to viewer ship
  const handleAddViewer = async (viewerShipId, viewerId) => {
    try {
      let response = await API.get("addViewer/" + viewerShipId + "/connected");
      if (response?.status) {
        console.log("res", response);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleAddMoney = () => {
    navigate("/profile");
  };
  return (
    <div className={styles.grouppurchasepayment_container}>
      {loading && !videoDetails ? (
        <GroupPurchasePaymentSkeleton />
      ) : (
        <>
          <div className={styles.grouppurchasepayment_container_box}>
            <div className={styles.grouppurchasepayment_container_box_wallet}>
              <div
                onClick={
                  !upiPaymentLoading && !walletPaymentLoading
                    ? handlePayWatchPartyFromWallet
                    : undefined
                }
                className={`${
                  styles.grouppurchasepayment_container_box_wallet_box
                } ${walletPaymentLoading ? styles.custome_center : ""}`}
              >
                {walletPaymentLoading ? (
                  <Spinner as={"span"} size="lg" />
                ) : (
                  <>
                    <div>
                      <label>{t("Pay from wallet")}</label>
                      <label>
                        {t("Remaining Balance")} : Rs.{walletBalance}
                      </label>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                    >
                      <path
                        d="M22.5 38.25L33.75 27L22.5 15.75"
                        stroke="white"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </>
                )}
              </div>
            </div>
            <span className={styles.grouppurchasepayment_container_box_or}>
              OR
            </span>
            <div className={styles.grouppurchasepayment_container_box_upi}>
              <div
                onClick={
                  !upiPaymentLoading && !walletPaymentLoading
                    ? handlePayWatchParty
                    : undefined
                }
                className={`${
                  styles.grouppurchasepayment_container_box_upi_box
                } ${upiPaymentLoading ? styles.custome_center : ""}`}
              >
                {upiPaymentLoading ? (
                  <Spinner as={"span"} size="lg" />
                ) : (
                  <>
                    <div>
                      <label>{t("Other payment mode")}</label>
                      <label>{t("UPI / Credit card")}</label>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                    >
                      <path
                        d="M22.5 38.25L33.75 27L22.5 15.75"
                        stroke="white"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </>
                )}
              </div>
              <div></div>
            </div>
          </div>

          <div className={styles.grouppurchasepayment_container_Add_money}>
            <div
              onClick={
                !upiPaymentLoading && !walletPaymentLoading
                  ? handleAddMoney
                  : undefined
              }
              className={styles?.grouppurchasepayment_container_Add_money_box}
            >
              <label>{t("Add money")}</label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M20 11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143V0H11.4286V8.57143H20V11.4286Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupPurchasePayment;
