export function durationConverter(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Only include hours or minutes if they are non-zero
  const hoursText = hours > 0 ? `${hours}h` : "";
  const minutesText = minutes > 0 ? `${minutes}min` : "";
  console.log("converted time", `${hoursText} ${minutesText}`.trim());

  return `${hoursText} ${minutesText}`.trim(); // Remove extra spaces
}
