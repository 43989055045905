import React from "react";

export const parseCustomJsonString = (inputString = "") => {
  if (!inputString) {
    return;
  }

  var modifiedString = inputString.substring(1, inputString.length - 1);
  var keyValuePairs = modifiedString.split(",");
  var result = {};

  keyValuePairs.forEach(function (pair) {
    var parts = pair.split(": ");
    var key = parts[0]?.trim();
    var value = parts[1]?.trim().replace(/"|}/g, "");

    result[key] = value;
    console.log("result[key] = value;: ", result[key], value);
  });

  return result;
};
