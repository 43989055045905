import React from "react";

export const convertTimeStamp = (timestamp = "") => {
  const dt = new Date(timestamp);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = dt.toLocaleDateString("en-US", options);
  return formattedDate;
};
