export function groupPurchaseDetails(
  isSeries,
  seasonNumber,
  episodeNumber,
  data,
  priceType,
  deviceType
) {
  let finalData = {};
  console.log("device type", deviceType);
  if (deviceType == "laptop") {
    deviceType = "web";
  } else if (deviceType == "mobile") {
    deviceType = "mobile";
  } else {
    deviceType = "tv";
  }
  let seasonData = null;
  let episodeData = null;

  if (isSeries) {
    console.log("inside isSeries");

    seasonData = data?.seasons?.find(
      (item) => item?.seasonNumber == seasonNumber
    );
    if (seasonNumber && episodeNumber) {
      console.log("inside episodes");

      episodeData = seasonData?.episodes?.find(
        (item) => item?.episodeNumber == episodeNumber
      );
      finalData.title = `S-${seasonNumber} E-${episodeNumber} ${episodeData?.title}`;
      finalData.duration = episodeData?.duration;
      finalData.backgroundImage = episodeData?.thumbnail?.file;
      finalData.synopsis = episodeData?.synopsis;
      finalData.crew = episodeData?.crew;
      finalData.cast = episodeData?.cast;
      finalData.cbfc_Rating = seasonData?.cbfc_Rating;
      finalData.genre = data?.genre;
      finalData.Prices = seasonData?.devicePricing?.[deviceType]?.[priceType];
    } else {
      console.log("inside isSeries season");

      finalData.title = `S-${seasonNumber} ${data?.title}`;
      finalData.cast = data?.cast;
      finalData.crew = data?.crew;
      finalData.synopsis = data?.synopsis;
      finalData.duration = seasonData?.totalDuration;
      finalData.backgroundImage = data?.backgroundImage?.file;
      finalData.cbfc_Rating = seasonData?.cbfc_Rating;
      finalData.genre = data?.genre;
      finalData.Prices = seasonData?.devicePricing?.[deviceType]?.[priceType];
    }
  } else {
    console.log("inside else");
    finalData.title = data?.title;
    finalData.cast = data?.cast;
    finalData.crew = data?.crew;
    finalData.synopsis = data?.synopsis;
    finalData.duration = data?.duration;
    finalData.backgroundImage = data?.keyArt2?.file;
    finalData.cbfc_Rating = data?.cbfc_Rating;
    finalData.genre = data?.genre;
    finalData.Prices = data?.devicePricing?.[deviceType]?.[priceType];
  }

  return finalData;
}
