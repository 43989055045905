import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./EpisodeCard.module.css";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TicketCard from "../../../../../components/TicketCard/TicketCard";

const EpisodeCard = ({
  imageSrc,
  title,
  duration,
  description,
  cast,
  crew,
  paymentStatus,
  episodeNumber,
  seasonNumber,
  deviceType,
  cbfc_Rating,
  PricesGroups,
  PricesIndividuals,
}) => {
  // const [deviceType, setDeviceType] = useState("laptop");
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const showMoreToggle = () => {
    setShowMore((e) => !e);
  };

  useEffect(() => {
    setShowMore(false);
  }, [seasonNumber]);

  // Define layouts based on device type
  const renderContent = () => {
    switch (deviceType) {
      case "mobile":
        return (
          <div
            className={`${styles.mobileCard} ${
              isExpanded ? styles.mobileExpanded : ""
            }`}
          >
            <div className={styles.mobileHeader} onClick={toggleExpand}>
              <div className={styles.mobileTitleWrapper}>
                <label className={styles.mobileEpisodeLabel}>{title}</label>
              </div>
              {!isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                </span>
              )}
              {isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </span>
              )}
            </div>

            {isExpanded && (
              <div className={styles.mobileDetails}>
                <div className={styles.mobileContainer}>
                  <div className={styles.mobileImageContainer}>
                    <img
                      src={imageSrc}
                      alt="Episode Thumbnail"
                      className={styles.mobileImage}
                    />
                  </div>
                  <div>
                    <div className={styles.mobileInfo}>
                      <span className={styles.mobileDuration}>{duration}</span>
                      {cbfc_Rating && (
                        <span className={styles.mobileIcon}>{cbfc_Rating}</span>
                      )}{" "}
                      {/* Placeholder for age rating icon */}
                    </div>
                    <div className={styles.mobileContent}>
                      {/* <p className={styles.mobileDescription}>{description}</p> */}
                      <div className={styles.mobileDescription}>
                        {showMore
                          ? description
                          : `${description.slice(0, 100)}...`}
                        <span
                          className={styles.toggleButton}
                          onClick={showMoreToggle}
                        >
                          {showMore ? " See Less" : " See More"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.laptopCastAndCrew}>
                  {cast && cast?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>
                        {" "}
                        {t("Cast")} :
                      </div>
                      {cast?.map((item, index) => (
                        <label className={styles.laptopCastBadgeWrap}>
                          {item?.name} as {item?.character}
                        </label>
                      ))}
                    </div>
                  )}

                  {crew && crew?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>
                        {" "}
                        {t("Crew")} :
                      </div>
                      {crew?.map((item, index) => (
                        <label className={styles.laptopCastBadgeWrap}>
                          {item?.name} ({item?.role})
                        </label>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <TicketCard
                    paymentStatus={paymentStatus}
                    seasonNumber={seasonNumber}
                    episodeNumber={episodeNumber}
                    isSeries={true}
                    PricesGroups={PricesGroups}
                    PricesIndividuals={PricesIndividuals}
                  />
                </div>
              </div>
            )}
          </div>
        );
      case "tablet":
        return (
          <div
            className={`${styles.tabletCard} ${
              isExpanded ? styles.tabletExpanded : ""
            }`}
          >
            <div className={styles.tabletHeader} onClick={toggleExpand}>
              <div className={styles.tabletTitleWrapper}>
                <label className={styles.tabletEpisodeLabel}>{title}</label>
              </div>
              {!isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                </span>
              )}
              {isExpanded && (
                <span className={styles.mobileArrow}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </span>
              )}
            </div>

            {isExpanded && (
              <div className={styles.tabletDetails}>
                <div className={styles.tabletContainer}>
                  <div className={styles.tabletImageContainer}>
                    <img
                      src={imageSrc}
                      alt="Episode Thumbnail"
                      className={styles.tabletImage}
                    />
                  </div>
                  <div>
                    <div className={styles.tabletInfo}>
                      <span className={styles.tabletDuration}>{duration}</span>
                      {cbfc_Rating && (
                        <span className={styles.mobileIcon}>{cbfc_Rating}</span>
                      )}{" "}
                      {/* Placeholder for age rating icon */}
                    </div>
                    <div className={styles.tabletContent}>
                      {/* <p className={styles.tabletDescription}>{description}</p> */}
                      <div class={styles.laptopDescriptionWrapper}>
                        {/* <p className={styles.laptopDescription}>{description}</p> */}
                        {showMore
                          ? description
                          : `${description.slice(0, 100)}...`}
                        <span
                          className={styles.toggleButton}
                          onClick={showMoreToggle}
                        >
                          {showMore ? " See Less" : " See More"}
                        </span>
                      </div>

                      <div className={styles.laptopCastAndCrew}>
                        {cast && cast?.length >= 1 && (
                          <div className={styles.laptopCastBadge}>
                            <div className={styles.laptopCastBadge}>
                              {t("Cast")} :
                            </div>
                            {cast?.map((item, index) => (
                              <label className={styles.laptopCastBadgeWrap}>
                                {item?.name} as {item?.character}
                              </label>
                            ))}
                          </div>
                        )}

                        {crew && crew?.length >= 1 && (
                          <div className={styles.laptopCastBadge}>
                            <div className={styles.laptopCastBadge}>
                              {t("Crew")} :
                            </div>
                            {crew?.map((item, index) => (
                              <label className={styles.laptopCastBadgeWrap}>
                                {item?.name} ({item?.role})
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <TicketCard
                    paymentStatus={paymentStatus}
                    seasonNumber={seasonNumber}
                    episodeNumber={episodeNumber}
                    isSeries={true}
                    PricesGroups={PricesGroups}
                    PricesIndividuals={PricesIndividuals}
                  />
                </div>
              </div>
            )}
          </div>
        );
      case "laptop":
        // default:
        return (
          <div className={styles.laptopCard}>
            <div className={styles.laptopHeader}>
              <div className={styles.laptopImage}>
                <img
                  src={imageSrc}
                  alt="Episode Thumbnail"
                  className={styles.laptopImageImg}
                />
              </div>
              <div className={styles.laptopContent}>
                <h4 className={styles.laptopEpisodeLabel}>{title}</h4>
                <div className={styles.laptopInfo}>
                  {/* <label className={styles.laptopDate}>{releaseDate}</label> */}
                  <span className={styles.laptopDuration}>{duration}</span>
                  {cbfc_Rating && (
                    <span className={styles.mobileIcon}>{cbfc_Rating}</span>
                  )}{" "}
                </div>

                <div class={styles.laptopDescriptionWrapper}>
                  {/* <p className={styles.laptopDescription}>{description}</p> */}
                  {showMore ? description : `${description.slice(0, 100)}...`}
                  <span
                    className={styles.toggleButton}
                    onClick={showMoreToggle}
                  >
                    {showMore ? " See Less" : " See More"}
                  </span>
                </div>

                <div className={styles.laptopCastAndCrew}>
                  {cast && cast?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>
                        {t("Cast")} :
                      </div>
                      {cast?.map((item, index) => (
                        <label className={styles.laptopCastBadgeWrap}>
                          {item?.name} as {item?.character}
                        </label>
                      ))}
                    </div>
                  )}

                  {crew && crew?.length >= 1 && (
                    <div className={styles.laptopCastBadge}>
                      <div className={styles.laptopCastBadge}>
                        {t("Crew")} :
                      </div>
                      {crew?.map((item, index) => (
                        <label className={styles.laptopCastBadgeWrap}>
                          {item?.name} ({item?.role})
                        </label>
                      ))}
                    </div>
                  )}
                </div>
                {/* { (
                  <div className={styles.laptopFreeEpisode}>First episode free</div>
                )}
                { (
                  <div className={styles.laptopPrimeEpisode}>
                    <span>🌟</span> Watch with a Prime membership
                  </div>
                )} */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                minWidth: "25rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TicketCard
                paymentStatus={paymentStatus}
                seasonNumber={seasonNumber}
                episodeNumber={episodeNumber}
                isSeries={true}
                PricesGroups={PricesGroups}
                PricesIndividuals={PricesIndividuals}
              />
            </div>
          </div>
        );
    }
  };

  return <div className={styles.card}>{renderContent()}</div>;
};

EpisodeCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  releaseDate: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default EpisodeCard;
