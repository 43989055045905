import React, { useRef, useState } from "react";
import styles from "./ContactUs.module.css"; // Import CSS module
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { baseUrl } from "../../Api-config/auth";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  generateTokenHandler,
  captchakey,
} from "../../utils/generateTokenHandler";
import ScrollToTop from "../../utils/ScrollToTop";
import { cmsBaseUrl } from "../../Api-config/cms";
import { Form, Spinner } from "react-bootstrap";

const ContactUs = () => {
  window.trackUser(null);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = contactForm;
  const [status, setStatus] = useState(false);
  const recaptchaRef = useRef(null);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    let slug = await generateTokenHandler();
    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    if (!captchaToken) {
      setMsg("Please verify reCaptcha");
      setTimeout(() => setMsg(null), 4000);
      setLoading(false);
      return;
    }

    // reCAPTCHA verification request
    try {
      const response = await axios.post(
        baseUrl + "verifyCaptcha",
        { token: captchaToken },
        { headers: { slug } }
      );

      if (!response?.data?.status || !response?.data?.data?.success) {
        setMsg("Please verify reCaptcha");
        setTimeout(() => setMsg(null), 4000);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);

      setMsg("Error verifying reCaptcha");
      setTimeout(() => setMsg(null), 4000);
      setLoading(false);
      return;
    }

    // Form validation
    if (!name || !email || !message) {
      setStatus(false);
      setMsg("Please fill all the fields");
      setTimeout(() => setMsg(null), 4000);
      setLoading(false);
      return;
    }

    // Contact API request
    try {
      let slug1 = await generateTokenHandler();
      await axios.post(cmsBaseUrl + "/api/consumerApi/contactUs", contactForm, {
        headers: { slug: slug1 },
      });

      setStatus(true);
      setMsg("Your message has been successfully submitted.");

      setTimeout(() => setMsg(null), 4000);
      setContactForm({ name: "", email: "", message: "" });
    } catch (error) {
      setStatus(false);
      setMsg("Something went wrong.");
      setTimeout(() => setMsg(null), 4000);
    }

    setLoading(false);
  };

  return (
    <div className={styles.contactus_container}>
      <ScrollToTop />
      <div className={styles["form-container"]}>
        <h2 className={styles["form-title"]}>Contact Us 🌻</h2>
        <p className={styles["form-text"]}>Need Assistance? Contact us</p>
        <form onSubmit={handleClick}>
          <input
            required
            className={styles["form-input"]}
            type="text"
            placeholder="Enter Name"
            value={contactForm.name}
            onChange={(e) =>
              setContactForm({ ...contactForm, name: e.target.value })
            }
          />
          <input
            required
            className={styles["form-input"]}
            type="email"
            placeholder="Enter Email"
            value={contactForm.email}
            onChange={(e) =>
              setContactForm({ ...contactForm, email: e.target.value })
            }
          />
          <textarea
            required
            className={styles["form-textarea"]}
            placeholder="Enter your message here"
            rows="8"
            value={contactForm.message}
            onChange={(e) =>
              setContactForm({ ...contactForm, message: e.target.value })
            }
          ></textarea>

          <div className={styles["recaptcha-container"]}>
            <ReCAPTCHA ref={recaptchaRef} sitekey={captchakey} />
          </div>

          {msg && (
            <p
              className={styles["modal-text"]}
              style={{ color: status ? "green" : "red" }}
            >
              {msg}
            </p>
          )}

          <button
            type="submit"
            disabled={loading}
            className={styles["submit-button"]}
          >
            {loading ? <Spinner /> : "Send Message"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
