import React, { useEffect, useState } from "react";
import styles from "./ResumePlayViaAds.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../utils/apiService";
import { getDeviceType } from "../../utils/getDeviceType";
import { v4 as uuidv4 } from "uuid";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
const ResumePlayViaAds = ({
  isAvod,
  isSeries,
  episodeNumber,
  seasonNumber,
  video_id,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [resumeViewerShipAds, setResumeViewerShipAds] = useState(null);
  const [showAdModal, setShowAdModal] = useState(false);

  const handlePayViaAds = async () => {
    console.log("add to fav--------");

    const viewerShipId =
      resumeViewerShipAds == null ? uuidv4() : resumeViewerShipAds;
    let data = {
      videoId: video_id,
      viewerShipId: viewerShipId,
      // "location": jsonEncode(location),
      isSeries: isSeries,
      isClicked: false,
      adType: "rewarded",
    };

    if (isSeries) {
      data.seasonNumber = seasonNumber;
      data.episodeNumber = 1;
    }

    try {
      let response = await API.post("createWatchedAd", data);
      if (response?.status && response?.response_code == 200) {
        sessionStorage.setItem("watchType", "watchParty");
        sessionStorage.setItem("videoDetails", video_id);
        localStorage.setItem("playWithAds", true);
        sessionStorage.setItem("viewerShipId", viewerShipId);
        localStorage.setItem(
          "deviceDetails",
          JSON.stringify({
            deviceType: getDeviceType(),
          })
        );
        if (isSeries) {
          sessionStorage.setItem("seasonNumber", seasonNumber);
          sessionStorage.setItem("episodeNumber", 1);
        }
        navigate("/paymentSuccess");
      }
    } catch (error) {}
  };

  const getStatusViaAds = async () => {
    console.log("get Status via ads--------");

    const data = {
      videoId: video_id,
      // "viewerShipId": viewerShipId,
      isSeries: isSeries,
    };

    if (isSeries) {
      data.seasonNumber = seasonNumber;
      data.episodeNumber = 1;
    }
    try {
      let response = await API.post("getWatchedAdByViewer", data);
      if (response?.status && response?.response_code == 200) {
        setResumeViewerShipAds(response?.data?.viewerShipId);
      } else {
        setResumeViewerShipAds(null);
      }
    } catch (error) {
      setResumeViewerShipAds(null);
    }
  };

  useEffect(() => {
    getStatusViaAds();
  }, []);

  return (
    <>
      <button
        onClick={() => setShowAdModal(true)}
        className={`${isAvod ? styles.disabled : ""}`}
        disabled={isAvod}
      >
        {resumeViewerShipAds ? t("Resume via Ads") : t("Watch for free")}
      </button>

      <Modal
        show={showAdModal}
        className={styles.resumePlayViaAds_modal}
        onHide={() => setShowAdModal(false)}
        // backdrop="static"
        centered
      >
        <Modal.Header>
          <Row>
            <Col>Watch for Free with Ads</Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <svg
                viewBox="-0.5 0 25 25"
                fill="none"
                style={{ cursor: "pointer" }}
                width={"20px"}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowAdModal(false)}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M3 21.32L21 3.32001"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M3 3.32001L21 21.32"
                    stroke="#017bf4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className={styles.resumePlayViaAds_modal_body}>
          <Row>
            <Col>Watch for Free with Ads</Col>
          </Row>
          To keep this content free for you, we need to show ads. These ads help
          us cover the ticket price so we can support creators and bring you
          more amazing content. Enjoy your show! 🎬🍿
        </Modal.Body>
        <Modal.Footer className={styles.resumePlayViaAds_modal_footer}>
          <button
            className={styles.resumePlayViaAds_modal_btn}
            onClick={handlePayViaAds}
          >
            Watch for free
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResumePlayViaAds;
