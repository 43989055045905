import React from "react";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import TopNavBar from "./topNavBar/TopNavBar";
import SideBar from "./sideBar/SideBar";

const Header = () => {
  return (
    <div className={styles.header_container}>
      <div className={styles.header_container_topnavbar}>
        <TopNavBar />
      </div>
      <div className={styles.header_container_sidebar}>
        <SideBar />
      </div>
    </div>
  );
};

export default Header;
