import React from "react";

export const getVideoDetailsForDetailsScreen = (
  isSeries,
  seasonNumber,
  episodeNumber,
  data,
  priceTypeForIndividuals,
  priceTypeForIndividualsEpisodes,
  priceTypeForGroups,
  priceTypeForGroupsEpisodes,
  deviceType
) => {
  let finalData = {};
  console.log("device type", deviceType);
  if (deviceType == "laptop") {
    deviceType = "web";
  } else if (deviceType == "mobile") {
    deviceType = "mobile";
  } else {
    deviceType = "tv";
  }
  console.log("episodes", priceTypeForGroupsEpisodes);
  console.log("season", priceTypeForGroups);

  let seasonData = null;
  let episodeData = null;

  if (isSeries) {
    console.log("inside isSeries");

    seasonData = data?.seasons?.find(
      (item) => item?.seasonNumber == seasonNumber
    );
    if (seasonNumber && episodeNumber) {
      console.log("inside episodes");

      episodeData = seasonData?.episodes?.find(
        (item) => item?.episodeNumber == episodeNumber
      );
      finalData.title = `S-${seasonNumber} E-${episodeNumber} ${episodeData?.title}`;
      finalData.duration = episodeData?.duration;
      finalData.backgroundImage = episodeData?.thumbnail?.file;
      finalData.synopsis = episodeData?.synopsis;
      finalData.crew = episodeData?.crew;
      finalData.cast = episodeData?.cast;
      finalData.cbfc_Rating = seasonData?.cbfc_Rating;
      finalData.genre = data?.genre;
      finalData.PricesIndividuals =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForIndividuals];
      finalData.PricesGroups =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForGroups];
      finalData.trailers = seasonData?.trailers;
      finalData.isAvod = seasonData?.isAvod;
      finalData.director = data?.director;
      finalData.languages = data?.languages;
      finalData.episodes = seasonData?.episodes;
      finalData.seasonNumber = seasonNumber;
      finalData.PricesIndividualsEpisodes =
        seasonData?.devicePricing?.[deviceType]?.[
          priceTypeForIndividualsEpisodes
        ];
      finalData.PricesGroupsEpisodes =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForGroupsEpisodes];
    } else {
      console.log("inside isSeries season");

      finalData.title = `S-${seasonNumber} ${data?.title}`;
      finalData.cast = data?.cast;
      finalData.crew = data?.crew;
      finalData.synopsis = data?.synopsis;
      finalData.duration = seasonData?.totalDuration;
      finalData.backgroundImage = data?.backgroundImage?.file;
      finalData.cbfc_Rating = seasonData?.cbfc_Rating;
      finalData.genre = data?.genre;
      finalData.PricesIndividuals =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForIndividuals];
      finalData.PricesGroups =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForGroups];
      finalData.trailers = seasonData?.trailers;
      finalData.isAvod = seasonData?.isAvod;
      finalData.director = data?.director;
      finalData.languages = data?.languages;
      finalData.episodes = seasonData?.episodes;
      finalData.seasonNumber = seasonNumber;
      finalData.PricesIndividualsEpisodes =
        seasonData?.devicePricing?.[deviceType]?.[
          priceTypeForIndividualsEpisodes
        ];
      finalData.PricesGroupsEpisodes =
        seasonData?.devicePricing?.[deviceType]?.[priceTypeForGroupsEpisodes];
    }
  } else {
    console.log("inside else");
    finalData.title = data?.title;
    finalData.cast = data?.cast;
    finalData.crew = data?.crew;
    finalData.synopsis = data?.synopsis;
    finalData.duration = data?.duration;
    finalData.backgroundImage = data?.backgroundImage?.file;
    finalData.cbfc_Rating = data?.cbfc_Rating;
    finalData.genre = data?.genre;
    finalData.PricesIndividuals =
      data?.devicePricing?.[deviceType]?.[priceTypeForIndividuals];
    finalData.PricesGroups =
      data?.devicePricing?.[deviceType]?.[priceTypeForGroups];
    finalData.trailers = data?.trailers;
    finalData.isAvod = data?.isAvod;
    finalData.director = data?.director;
    finalData.languages = data?.languages;
    finalData.episodes = null;
    finalData.seasonNumber = null;
  }

  return finalData;
};
