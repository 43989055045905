import React, { useState } from "react";
import styles from "./Rating.module.css";
import API from "../../utils/apiService";
import { getToast } from "../../utils/getToast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StarRating from "../../components/ratings/StarRating";
import { Spinner } from "react-bootstrap";

const Rating = () => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(1);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [comment, setComment] = useState(null);

  const handleSubmitRating = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        videoId: sessionStorage.getItem("videoRated"),
        transactionId: sessionStorage.getItem("transcRated"),
        rating: rating,
      };

      if (!comment || comment != "") {
        data.comment = comment;
      }
      let response = await API.post("/userEngagement.v1", data);
      if (response?.status) {
        getToast(response?.message, "top-right", "success");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <div>
      <div className={styles.rating_container}>
        <h2>
          {t(
            "Become a movie critic by leaving a review and creating your own playlist"
          )}
        </h2>

        <h6>{t("What did you think about the movie you just watched?")}</h6>

        <StarRating rating={rating} onRatingChange={handleRatingChange} />
        <form onSubmit={handleSubmitRating}>
          <textarea
            name=""
            id=""
            onChange={(e) => setComment(e.target.value)}
            rows="4"
            placeholder="Leave a review here"
            required
          ></textarea>
          <button
            disabled={loading}
            type="submit"
            className={styles.follow_btn}
          >
            {loading ? <Spinner /> : t("Submit")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Rating;
