import React from "react";
import styles from "./SearchList.module.css";
import movie_poster_icon from "../../assets/images/no_result_found.png";

const SearchItem = ({ title, keyArt1, genre, rating, isSeries, seasons }) => {
  return (
    <div className={styles.content_item}>
      <div className={styles.poster_container}>
        <img
          src={keyArt1?.file}
          onError={(e) => {
            e.target.src = movie_poster_icon;
          }}
        />
        {isSeries && seasons?.seasonNumber && (
          <div className={styles.carousel_item_chip}>
            <label>Season {seasons?.seasonNumber}</label>
          </div>
        )}
      </div>
      <div className={styles.content_item_header}>
        <h6>{title}</h6>
      </div>
    </div>
  );
};

export default SearchItem;
