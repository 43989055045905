import React, { useEffect, useRef, useState } from "react";
import "./carousel.css";
import { useDispatch } from "react-redux";
import styles from "./ListCarousel.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../utils/apiService";
import { getSeasonNumber } from "../../../redux/dataReducer/action";
import Slider from "react-slick";
import CarouselItems from "../carouselItems/CarouselItems";
import { NextBtn, PreviousBtn } from "../carouselBtns/CarouselBtns";

const ListCarousel = ({ title, data }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isArrowsVisible, setIsArrowsVisible] = useState(false);
  let sliderRef = useRef(null);
  console.log("data: ", data);
  const dispatch = useDispatch();
  // Calculate the minimum number of slides to show
  var settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: false,
    accessibility: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1580,
        settings: {
          slidesToShow: Math.min(data?.length || 0, 6),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(data?.length || 0, 5),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(data?.length || 0, 4),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(data?.length || 0, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(data?.length || 0, 1),
          slidesToScroll: 1,
        },
      },
    ],
  };
  let slidesToShow = Math.min(data?.length || 0, 7);
  // Update the settings with the calculated value
  const updatedSettings = {
    ...settings,
    slidesToShow,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleVideoClick = async (videoId, seasons, isSeries) => {
    if (isSeries) {
      dispatch(getSeasonNumber(seasons));
    } else {
      dispatch(getSeasonNumber(1));
    }

    let data;
    sessionStorage.setItem("isSeries", isSeries);

    if (isSeries) {
      console.log("season,,,,,,", seasons);

      data = {
        videoId,
        trailer: false,
        seasonNumber: parseInt(seasons),
        banner: false,
      };
    } else {
      data = {
        videoId,
        trailer: false,
        banner: false,
      };
    }

    try {
      let response = await API.post("videoClickOrTrailerPlayed", data);

      if (response?.status) {
        console.log("res: ", response);
      }
    } catch (error) {
      console.log("apis error", error?.message);
    }
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsArrowsVisible(false); // Reset visibility first

    if (screenWidth < 480 && data?.length >= 2) {
      setIsArrowsVisible(true);
    } else if (screenWidth < 800 && data?.length >= 3) {
      setIsArrowsVisible(true);
    } else if (screenWidth < 1024 && data?.length >= 5) {
      setIsArrowsVisible(true);
    } else if (screenWidth < 1200 && data?.length >= 6) {
      setIsArrowsVisible(true);
    } else if (screenWidth > 1200 && data?.length >= 7) {
      setIsArrowsVisible(true);
    }
  }, [screenWidth, data]); // Include `data` in dependencies

  return (
    <div className={styles.carousel_container}>
      <div className={styles.carousel_header}>
        <h4>{title}</h4>
        {isArrowsVisible && (
          <div className={styles.carousel_header_btns}>
            <PreviousBtn onClick={previous} />
            <NextBtn onClick={next} />
          </div>
        )}
      </div>
      {data?.length === 0 ? (
        <div className={styles.not_found_container}>
          <h6>No Results</h6>
        </div>
      ) : (
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...updatedSettings}
          className={styles.carousel_box}
        >
          {data?.map((el, i) => (
            <NavLink
              style={{
                textDecoration: "none",
                width: "100%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                handleVideoClick(
                  el?.videoId,
                  el?.seasons?.seasonNumber,
                  el?.isSeries
                )
              }
              key={i}
              to={`/details/${el?.videoId}`}
            >
              <CarouselItems {...el} />
            </NavLink>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ListCarousel;
