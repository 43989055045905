import React from "react";
import styles from "./SearchList.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSeasonNumber } from "../../redux/dataReducer/action";
import SearchItem from "./SearchItem";
const SearchList = ({ data }) => {
  const dispatch = useDispatch();

  const handleVideoClick = async (videoId, seasons, isSeries) => {
    if (isSeries) {
      dispatch(getSeasonNumber(seasons));
    } else {
      dispatch(getSeasonNumber(1));
    }
    sessionStorage.setItem("isSeries", isSeries);
  };

  return (
    <div className={styles.content_list_container}>
      {data?.map((el, i) => {
        return (
          <NavLink
            onClick={() =>
              handleVideoClick(
                el?.videoId,
                el?.seasons?.seasonNumber,
                el?.isSeries
              )
            }
            to={`/details/${el?.videoId}`}
          >
            <SearchItem {...el} key={i} />
          </NavLink>
        );
      })}
    </div>
  );
};

export default SearchList;
