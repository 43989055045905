import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../utils/apiService";
import SearchList from "../../components/searchList/SearchList";
import styles from "./Search.module.css";

const Search = () => {
  const { searchText } = useParams();
  const [movies, setMovies] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const q = urlParams.get("q");

  // get the search results
  const searchResult = async () => {
    setLoading(true);
    let data = {
      searchText: q,
    };
    try {
      let response = await API.post("search.v1", data);
      if (response?.status) {
        setMovies(response?.data || null);
      } else {
        setMovies(null);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchResult(q);
  }, [q]);

  return (
    <div className={styles.search_container}>
      <h5>
        {t("Titles related to :")} {q}
      </h5>
      <div
        className={
          styles.search_container_box +
          ` ${!movies || movies?.length <= 0 ? styles.custome_center : ""}`
        }
      >
        {!movies || movies?.length <= 0 ? (
          <div className={styles.search_container_not_found}>
            <label>{t("Result Not Found")}</label>
          </div>
        ) : (
          <SearchList data={movies} />
        )}
      </div>
    </div>
  );
};

export default Search;
