import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import ListCarousel from "../../components/carousels/carousel/ListCarousel";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getHomeDataContinueWatchingError,
  getHomeDataContinueWatchingReq,
  getHomeDataContinueWatchingSuccess,
  getHomeDataSuggestedForYouError,
  getHomeDataSuggestedForYouReq,
  getHomeDataSuggestedForYouSuccess,
  getHomeDataTrendingNowError,
  getHomeDataTrendingNowReq,
  getHomeDataTrendingNowSuccess,
  getHomeDataWhatsNewError,
  getHomeDataWhatsNewReq,
  getHomeDataWhatsNewSuccess,
} from "../../redux/homeReducer/action";
import API from "../../utils/apiService";
import CarouselSkeleton from "../../components/Skeletons/CarouselSkeleton";
import renderEpisodesInCarousel from "../../utils/renderEpisodesInCarousel";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { category_slug } = useParams();
  const { trendingData, continueWatchingData, whatsNewData, suggestedData } =
    useSelector((state) => state?.homeReducer);

  const [whatsNewLoading, setWhatsNewLoading] = useState(true);
  const [trendingVideoLoading, setTrendingVideoLoading] = useState(true);
  const [suggestedVideoLoading, setSuggestedVideoLoading] = useState(true);
  const [continueWatchingLoading, setContinueWatchingLoading] = useState(true);

  // continue wathcing
  const continueWathcing = async () => {
    if (continueWatchingData[category_slug]?.length <= 0) {
      setContinueWatchingLoading(true);
      dispatch(getHomeDataContinueWatchingReq(category_slug));
    }

    try {
      let response = await API.get("getRatingMovieList");
      if (response?.status) {
        dispatch(
          getHomeDataContinueWatchingSuccess({
            data: response?.data,
            slug: category_slug,
          })
        );
        setContinueWatchingLoading(false);
      } else {
        dispatch(getHomeDataWhatsNewError(category_slug));
      }
    } catch (error) {
      dispatch(getHomeDataContinueWatchingError(category_slug));
      setContinueWatchingLoading(false);
    } finally {
      setContinueWatchingLoading(false);
    }
  };

  // get the new content
  const getWhatsNew = async () => {
    if (whatsNewData[category_slug]?.length >= 1) {
      setWhatsNewLoading(false);
      return;
    }
    dispatch(getHomeDataWhatsNewReq(category_slug));
    setWhatsNewLoading(true);

    try {
      let response = await API.get(`getNewVideos_v2?category=${category_slug}`);
      if (response?.status) {
        let video = renderEpisodesInCarousel(response?.data?.videos);

        setWhatsNewLoading(false);
        dispatch(
          getHomeDataWhatsNewSuccess({
            data: video,
            slug: category_slug,
          })
        );
      } else {
        dispatch(getHomeDataWhatsNewError(category_slug));
      }
    } catch (error) {
      setWhatsNewLoading(false);
      dispatch(getHomeDataWhatsNewError(category_slug));
    } finally {
      setWhatsNewLoading(false);
    }
  };
  // get the trending content
  const getTrendingContent = async () => {
    if (trendingData[category_slug]?.length >= 1) {
      setTrendingVideoLoading(false);
      return;
    }
    dispatch(getHomeDataTrendingNowReq(category_slug));
    setTrendingVideoLoading(true);

    try {
      let response = await API.get(
        `getTrendingVideos_v2?category=${category_slug}`
      );
      if (response?.status) {
        let video = renderEpisodesInCarousel(response?.data?.videos);
        setTrendingVideoLoading(false);
        dispatch(
          getHomeDataTrendingNowSuccess({
            data: video,
            slug: category_slug,
          })
        );
      } else {
        dispatch(getHomeDataTrendingNowError(category_slug));
      }
    } catch (error) {
      setTrendingVideoLoading(false);
      dispatch(getHomeDataTrendingNowError(category_slug));
    } finally {
      setTrendingVideoLoading(false);
    }
  };
  // get the suggested video
  const getSuggestedVideo = async () => {
    if (suggestedData[category_slug]?.length >= 1) {
      setSuggestedVideoLoading(false);
      return;
    }
    dispatch(getHomeDataSuggestedForYouReq(category_slug));
    setSuggestedVideoLoading(true);

    try {
      let response = await API.get(
        `suggestedVideoForViewer?category=${category_slug}`
      );
      if (response?.status) {
        let video = renderEpisodesInCarousel(response?.data?.videos);

        setSuggestedVideoLoading(false);
        dispatch(
          getHomeDataSuggestedForYouSuccess({
            data: video,
            slug: category_slug,
          })
        );
      } else {
        dispatch(getHomeDataSuggestedForYouError(category_slug));
      }
    } catch (error) {
      setSuggestedVideoLoading(false);
      dispatch(getHomeDataSuggestedForYouError(category_slug));
    } finally {
      setSuggestedVideoLoading(false);
    }
  };

  useEffect(() => {
    getWhatsNew();
    continueWathcing();
    getSuggestedVideo();
    getTrendingContent();
  }, [category_slug]);

  return (
    <div className={styles.home_container}>
      {/* banner  */}
      <HomeBanner category_slug={category_slug} />

      {/* continue watching*/}
      {continueWatchingLoading && <CarouselSkeleton />}
      {continueWatchingData[category_slug]?.length >= 1 ? (
        <ListCarousel
          title={t("Continue Watching")}
          data={continueWatchingData[category_slug]}
        />
      ) : (
        ""
      )}

      {/* what's new  */}
      {whatsNewLoading && <CarouselSkeleton />}
      {!whatsNewLoading && whatsNewData[category_slug]?.length >= 1 && (
        <ListCarousel
          title={t("What's New")}
          data={whatsNewData[category_slug]}
        />
      )}

      {/* trending now */}
      {trendingVideoLoading && <CarouselSkeleton />}
      {!trendingVideoLoading && trendingData[category_slug]?.length >= 1 && (
        <ListCarousel
          title={t("Trending Now")}
          data={trendingData[category_slug]}
        />
      )}

      {/* suggested for you   */}
      {suggestedVideoLoading && <CarouselSkeleton />}
      {!suggestedVideoLoading && suggestedData[category_slug]?.length >= 1 && (
        <ListCarousel
          title={t("Suggested for you")}
          data={suggestedData[category_slug]}
        />
      )}
    </div>
  );
};

export default Home;
