import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToast } from "./getToast";
import { generateTokenHandler } from "./generateTokenHandler";
import { getDeviceType } from "./getDeviceType";
import { parseCustomJsonString } from "./parseCustomJsonString";
import { baseUrl } from "../Api-config/auth";
import { movieBaseUrl } from "../Api-config/movie";

const createAPIInstance = async () => {
  let slug = await generateTokenHandler();
  const instance = axios.create({
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      slug: slug,
      devicetype: getDeviceType(),
    },
  });

  instance.interceptors.request.use(
    (config) => {
      let details = parseCustomJsonString(window.deviceDetails) || {};
      if (details?.androidId || details?.deviceId) {
        config.headers.appid = details?.androidId || details?.deviceId;
      }

      const token = localStorage.getItem("authUser");
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => {
      console.error("Request Interceptor Error:", error);
      return Promise.reject(error);
    }
  );

  return instance;
};

const handleResponse = (response) => {
  if ([200, 201, 422, 401].includes(response.status)) {
    return response.data;
  } else {
    console.error("Unexpected response:", response);
    toast.error("An error occurred. Please try again.");
    throw new Error("API Error");
  }
};

const handleError = (error) => {
  const status = error?.response?.status;

  if ([401, 403].includes(status)) {
    localStorage.clear(); // Clear all local storage data
    sessionStorage.clear(); // Also clear session storage
    window.location.replace("/signin");
    setTimeout(() => {
      window.history.pushState(null, null, "/signin");
      window.history.replaceState(null, null, "/signin");
    }, 0);
    // toast.error("Session expired. Please sign in again.");
  } else if (status === 404) {
    // toast.error("Oops! The content you're looking for isn't available.");
  } else if (status === 500) {
    // toast.error("Something went wrong on our end. Please try again later.");
  } else if (status === 422) {
    // toast.error("Invalid request. Please check your input and try again.");
  } else {
    // toast.error("Failed to fetch data. Please check your connection.");
  }
  throw error;
};

const API = {
  async get(endpoint, useFullUrl = false) {
    const api = await createAPIInstance();
    return api
      .get(useFullUrl ? endpoint : `${movieBaseUrl}${endpoint}`)
      .then(handleResponse)
      .catch(handleError);
  },

  async post(endpoint, body, useFullUrl = false) {
    const api = await createAPIInstance();
    return api
      .post(useFullUrl ? endpoint : `${movieBaseUrl}${endpoint}`, body)
      .then(handleResponse)
      .catch(handleError);
  },

  async patch(endpoint, body) {
    const api = await createAPIInstance();
    return api
      .patch(`${movieBaseUrl}${endpoint}`, body)
      .then(handleResponse)
      .catch(handleError);
  },

  async delete(endpoint, body) {
    const api = await createAPIInstance();
    return api
      .delete(`${movieBaseUrl}${endpoint}`, { data: body })
      .then(handleResponse)
      .catch(handleError);
  },

  async postAuth(endpoint, body) {
    const api = await createAPIInstance();
    return api
      .post(`${baseUrl}${endpoint}`, body)
      .then(handleResponse)
      .catch((error) => {
        console.error("POST AUTH Error:", error);
        // toast.error("Authentication failed.");
        throw error;
      });
  },

  async getAuth(endpoint) {
    const api = await createAPIInstance();
    return api
      .get(`${baseUrl}${endpoint}`)
      .then(handleResponse)
      .catch((error) => {
        console.error("GET AUTH Error:", error);
        // toast.error("Failed to authenticate.");
        throw error;
      });
  },
};

export default API;
