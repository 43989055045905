import React, { useEffect, useState } from "react";
import styles from "./Support.module.css";
import { useTranslation } from "react-i18next";
import FAQItems from "../../components/faqItms/FAQItems";
import API from "../../utils/apiService";
import { getToast } from "../../utils/getToast";
import { Spinner } from "react-bootstrap";
import { convertTimeStamp } from "../../utils/convertTimeStamp";

const Support = () => {
  const { t } = useTranslation();
  const email = localStorage.getItem("email") || "";
  const [loading, setLoading] = useState(false);
  const [supportTicketList, setSupportTicketList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    "Account & Authentication"
  );

  const [formData, setFormData] = useState({
    subject: "",
    description: "",
  });

  const supportTickets = [
    "Account & Authentication",
    "Billing & Payments",
    "Content Access",
    "Technical Support",
    "General Inquiries & Feedback",
    "Report Abuse & Issues",
  ];

  const handleSelectCategory = (e) => {
    setSelectedCategory(e?.target?.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...formData, relatedTo: selectedCategory };
    if (
      [
        "Account & Authentication",
        "Billing & Payments",
        "Report Abuse & Issues",
      ].includes(selectedCategory)
    ) {
      data.severity = "high";
    } else if (
      ["Content Access", "Technical Support"].includes(selectedCategory)
    ) {
      data.severity = "medium";
    } else {
      data.severity = "low";
    }
    console.log("data: ", data);

    try {
      setLoading(true);
      let response = await API.post("createSupportTickets", data);
      if (response?.status) {
        getToast(response?.message, "top-right", "success");
      }
    } catch (error) {
      console.log("support page error", error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSupportTickets = async () => {
    try {
      let response = await API.get("getSupportTickets");
      if (response?.status) {
        setSupportTicketList(response?.data);
      } else {
        setSupportTicketList(null);
      }
    } catch (error) {
      console.log("support page error", error?.message);
    }
  };

  useEffect(() => {
    handleGetSupportTickets();
  }, []);

  return (
    <div className={styles.support_container}>
      <div className={styles.support_container_box}>
        <div className={styles.support_container_box_help}>
          <div className={styles.support_container_box_help_left}>
            <h4>{t("Welcome to our help centre!")}</h4>
            <p>
              {t(
                "We're here to help you with any problems you may be having with our product."
              )}
            </p>
            <div>
              <img />
            </div>
          </div>
          <div className={styles.support_container_box_help_right}>
            <form onSubmit={handleSubmit}>
              <div className={styles.support_container_box_help_right_name}>
                <div>
                  <label>{t("Last Name")}</label>
                  <input type="text" required />
                </div>
                <div>
                  <label>{t("First Name")}</label>
                  <input type="text" required />
                </div>
              </div>
              <div className={styles.support_container_box_help_right_email}>
                <div>
                  <label>{t("Email")}</label>
                  <input readOnly type="email" value={email} required />
                </div>
                <div>
                  <label>{t("Support Tickets")}</label>
                  <select onChange={handleSelectCategory}>
                    {supportTickets.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.support_container_box_help_right_message}>
                <div>
                  <label>{t("Subject")}</label>
                  <input
                    type="text"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, subject: e.target.value })
                    }
                    value={formData?.subject}
                  />
                </div>
              </div>
              <div className={styles.support_container_box_help_right_message}>
                <div>
                  <label>{t("Message")}</label>
                  <textarea
                    type="text"
                    rows={4}
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    value={formData?.description}
                  />
                </div>
              </div>
              <div className={styles.support_container_box_help_right_btns}>
                <div>
                  <input type="checkbox" required />
                  <span>
                    {t("I agree with Terms of Use and Privacy Policy")}
                  </span>
                </div>
                <button type="submit">
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("Send Message")
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        {supportTicketList?.length >= 1 && (
          <div className={styles.support_container_box_tickets}>
            <div className={styles.support_container_box_tickets_header}>
              <h4>{t("Support Tickets")}</h4>
              <p>
                {t(
                  "Check the status of your support tickets and view your ticket history."
                )}
              </p>
            </div>
            <div className={styles.support_container_box_tickets_body}>
              <div className={styles.support_container_box_tickets_body_header}>
                <div>{t("Ticket Id")}</div>
                <div>{t("Type")}</div>
                <div>{t("Description")}</div>
                <div>{t("Date & Time")}</div>
                <div>{t("Assigned")}</div>
              </div>
              {supportTicketList?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className={styles.support_container_box_tickets_body_items}
                  >
                    <div>{el?.ticketNumber}</div>
                    <div>{el?.relatedTo}</div>
                    <div>{el?.description}</div>
                    <div>{convertTimeStamp(el?.createdAt)}</div>
                    <div>{el?.status}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className={styles.support_container_box_faqs}>
          <div className={styles.support_container_box_faqs_header}>
            <h4>{t("Frequently Asked Questions")}</h4>
            <p>
              {t(
                "Got questions? We've got answers! Check out our FAQ section to find answers to the most common questions about Hall IV."
              )}
            </p>
          </div>
          <div className={styles.support_container_box_faqs_body}>
            <FAQItems />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
