import React from "react";
import styles from "./SelectSeason.module.css";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getSeasonNumber } from "../../../../redux/dataReducer/action";

const SelectSeason = ({ seasonsNumber, setSeasonsNumber, totalSeason }) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  function changeSeasonNumber(e) {
    setSeasonsNumber(e);
    dispatch(getSeasonNumber(e));
  }

  return (
    <div className={styles.selectSeason_container}>
      <div className={styles.selectSeason_container_header}>
        <h5>{t("Season's")}</h5>
      </div>
      <div className={styles.selectSeason_container_body}>
        {totalSeason === 1 ? (
          <div
            className={`${styles.option} ${
              totalSeason === 1 ? styles.singleSeason : ""
            }`}
          >
            Season 1
          </div>
        ) : (
          <Form.Select
            size="lg"
            className={`${styles.option_container} ${
              totalSeason === 1 ? styles.singleSeason : ""
            }`}
            value={seasonsNumber}
            onChange={(e) => changeSeasonNumber(e.target.value)}
            disabled={totalSeason === 1}
          >
            {Array.from({ length: totalSeason }, (_, index) => (
              <option
                className={styles?.option_item}
                key={index}
                value={index + 1}
              >
                Season {index + 1}
              </option>
            ))}
          </Form.Select>
        )}
      </div>
    </div>
  );
};

export default SelectSeason;
