import React from "react";
import { ToastContainer, toast } from "react-toastify";

export const getToast = (message, position, type) => {
  if (toast.isActive) {
    toast.dismiss();
  }
  const obj = {
    item: message,
    tag: type === "success" ? "Success" : "Failure",
    className: type === "success" ? "success" : "error",
  };
  const notifications =
    JSON.parse(sessionStorage.getItem("notifications")) || [];

  if (notifications.length >= 20) {
    notifications.shift();
  }

  notifications.push(obj);
  sessionStorage.setItem("notifications", JSON.stringify(notifications));

  switch (type) {
    case "success":
      toast.success(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        // onClose: () => setIsToastVisible(false),
      });
      break;
    case "error":
      toast.error(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        // onClose: () => setIsToastVisible(false),
      });
      break;

    case "warn":
      toast.warn(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        // onClose: () => setIsToastVisible(false),
      });

      break;

    case "info":
      toast.info(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        // onClose: () => setIsToastVisible(false),
      });

      break;
    // Add more cases for other types as needed
    default:
      // Handle unknown types or provide a default behavior
      toast(message, {
        position: position,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        // onClose: () => setIsToastVisible(false),
      });
      break;
  }
};
