import React from "react";
import styles from "./CarouselItems.module.css";
import movie_poster_icon from "../../../assets/svgs/movie_poster_icon.svg";
const CarouselItems = ({ _id, genre, keyArt1, title, isSeries, seasons }) => {
  return (
    <div key={_id} className={styles.carousel_item}>
      <div className={styles.poster_container}>
        <img
          src={keyArt1?.file}
          onError={(e) => {
            e.target.src = movie_poster_icon;
          }}
        />
        {isSeries && seasons?.seasonNumber && (
          <div className={styles.carousel_item_chip}>
            <label>Season {seasons?.seasonNumber}</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselItems;
