import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./DeleteAccountModal.module.css"; // Import CSS Module
import API from "../../utils/apiService";
import { useState } from "react";
import { ButtonLoader } from "../ButtonLoaders/ButtonLoader";
import { getToast } from "../../utils/getToast";

const DeleteAccountModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = async () => {
    setLoading(true);
    // Add delete account logic here
    try {
      let response = await API.getAuth("api/viewerApp/deleteAccount");
      if (response?.status) {
        getToast("success", "Verification code sent successfully", "right-top");
        navigate("/account-deletion");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className={styles.modalContent}>
        {" "}
        {/* Dark background applied */}
        <Modal.Header className={styles.modalHeader}>
          <Row className={styles.modalTitleRow}>
            <Col xs="auto">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 14v-2h2v2h-2zm0-4V7h2v5h-2z"
                  fill="#ff3333"
                />
              </svg>
            </Col>
            <Col className={styles.modalTitle}>{t("Are you sure?")}</Col>
          </Row>
          <span className={styles.closeIcon} onClick={handleClose}>
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <h6>
            {
              "Are you sure you want to delete your account? This action is permanent and cannot be undone."
            }
          </h6>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            className={styles.deleteButton}
            disabled={loading}
            onClick={handleDeleteAccount}
          >
            {loading ? <ButtonLoader /> : t("Delete Account")}
          </Button>
          <Button className={styles.cancelButton} onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
