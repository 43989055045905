import React from "react";
import styles from "./TermAndConditons.module.css";
const TermAndConditons = () => {
  return (
    <div className={styles.privacy_container}>
      <h4 className={styles.header_text}>Terms of use</h4>
      <ol>
        <li>
          Introduction: Welcome to Hall IV, an on-demand digital video streaming
          service/ platform which is owned, operated and managed by Boxofic
          Technologies Private Limited (“Boxofic”). Boxofic offers this
          platform, HallIV (“Platform”), including all information, tools and
          services available from this site to you, the user
          (“you/your/Viewer”), conditioned upon your acceptance of all terms,
          conditions, policies and notices stated here (which shall be amended
          from time to time) within the Territory. For the purposes of this
          Agreement, “Territory” shall mean the continents of Asia, North
          America, European Union and the United Kingdom. These terms of use
          (“Agreement”) are an electronic record in terms of Information
          Technology Act, 2000 and rules thereof as applicable and the amended
          provisions pertaining to electronic records in various statutes as
          amended by the Information Technology (Amendment) Act, 2008, (“IT
          Act”) and such other applicable amendments from time to time and shall
          govern the relationship between you and us.
          <br />
          <br />
          This Agreement is a legally binding agreement entered into between the
          Platform and the Viewer and you agree to access the Content (defined
          below) as per the terms in this Agreement. For the purposes of this
          Agreement, “Content” collectively, shall mean audiovisual content
          including videos, audio (for example music and other sounds),
          graphics, photos, audio, video, text (such as comments and scripts),
          branding (including trade names, trademarks, service marks, or logos),
          software, metrics, and other materials. For abundant clarity, Content
          is the responsibility of the person or entity that provides it to the
          Platform. The Platform is under no obligation to host or serve
          Content.
          <br />
          <br />
          By accessing the Platform, you agree to be bound by the terms and
          conditions of this Agreement including the access and terms of usage
          of the Platform. This Agreement is generated by a computer system and
          does not require any physical or digital signatures. The Platform may
          update this Agreement by posting a revised version on the Platform. By
          continuing to use our services, you accept any revised Agreement.
        </li>

        <li>
          . Registration/ creation of an account: You agree that in order to
          access the Platform and its services, (a) you are and shall be a
          registered user of the Platform; (b) you understand and agree to
          provide accurate and verifiable details in order to conduct
          Know-Your-Customer (KYC) process, if any; (c) you confirm that all the
          data and information supplied by you in relation to creation of your
          account on the Platform including without limitation, your email
          address, mobile number, name, login-id, password etc., (“Registration
          Data”) is true and accurate in all respects (d) your mobile number may
          be verified via one-time passwords (OTP); (collectively herein
          referred to as a valid “Account”) (e) you agree and confirm that you
          are at all times solely responsible for maintaining the
          confidentiality of your account credentials and are liable for any
          activities conducted through your account. If you are using a computer
          that others have access to, you must log out of your Account after
          each session. If you become aware of unauthorized access to your
          Account, you must change your password and notify the Platform
          immediately; and (f) you agree that you may only open an account for
          yourself and not for any other person/ entity/ individual
          <br />
          <br />
          The Platform accepts no liability for any losses, damages and/ or
          claims incurred as a result of either unauthorised access to your
          account and/ or account details shared by you.
        </li>

        <li>
          Eligibility: You agree that you must meet the following criteria to be
          eligible to avail services of the Platform: (a) you agree and confirm
          that you are at least 18 (eighteen) years of age and/ or have attained
          the age of majority as per applicable laws in the Territory in order
          to access this Platform (“Age of majority”) and/ or have obtained the
          necessary consent from a parent or legal guardian. You confirm that if
          you have not reached the Age of majority, using the Platform by you
          shall be deemed to be subject to the consent of a parent/ legal
          guardian and under the parent/ legal guardian’s supervision at all
          times; (b) you agree and confirm that you are a legal resident as per
          applicable laws in the Territory and have the right to access the
          Platform; (c) you agree and confirm that you hold a valid Account to
          be able to access the Platform; (d) you agree and confirm that the
          Platform reserves the right to modify/ change/ amend the eligibility
          criteria in accordance with applicable laws, regulations, Platform
          policies, and you agree to be responsible to and to adhere to such
          modifications; (e) you agree and confirm that you hold all the
          necessary rights and permissions to access the Platform
        </li>
        <li>
          Access to the Services and Viewer’s discretion: You may not subscribe
          to the Platform unless you have attained the Age of Majority. You
          understand that by using any of the services available in the
          Platform, you may encounter content that may be deemed offensive,
          indecent, or objectionable, which content may or may not be identified
          as having explicit language, sexual content, graphic violence and/ or
          mature themes and that the results of any search may automatically and
          unintentionally generate links or references to objectionable
          material. You understand and agree that such content may not be
          suitable for everyone. Nevertheless, you agree to use the Platform at
          your sole risk and that the Platform, its affiliates, agents,
          principals, or licensors shall have no liability to you for content
          that may be found to be offensive, indecent, or objectionable.
          Viewer’s discretion is advised.
        </li>

        <li>
          Grant of license to Viewer: Subject to payment, as applicable, and
          your compliance with the terms of this Agreement, the Platform grants
          you a limited, non-exclusive, nontransferable, non-sub licensable,
          revocable license to access and view the content made available on the
          Platform during the Viewing Period for personal, non-commercial,
          private use on the Platform only
        </li>
        <li>
          Compatibility Systems/ Accessibility: The Platform and its services
          can be availed through such devices, softwares, operating systems,
          platforms, including but not limited to a personal computer, portable
          media player, etc., approved and authorised by the Platform, from time
          to time or in some cases (as applicable), compatibility/ accessibility
          is subject to third parties and/ or manufacturers authorised by the
          Platform, therefore certain devices may gain/ loose accessibility/
          compatibility with time. In the event of any updates to the Platform
          or any part thereof, you agree and confirm that such updates shall
          take place automatically without any action by you and/ or where an
          update is not automatic, such update shall take place upon
          installation by you to access the Platform. You agree that in some
          cases you may be required to update your device in order to make such
          device accessible/ compatible with the Platform. You acknowledge and
          agree that the playback quality/ streaming quality shall depend on a
          number of factors including but not limited to the device used to
          access such Content. You agree and confirm that the Platform shall not
          be liable and responsible to you in the event of inaccessibility/
          incompatibility of your device with the Platform. If the Platform
          detects that Content streaming to you may be interrupted or may
          otherwise not play properly due to bandwidth constraints or other
          factors, the Platform may decrease the resolution and file size of the
          streamed Content in an effort to provide an uninterrupted viewing
          experience to you. While the Platform strives to provide you a high
          quality viewing experience subject to the Content viewing option
          chosen by you, it makes no guarantee as to the resolution or quality
          of the Content you will receive when streaming, even if you have paid
          extra for access to high definition, ultra-high definition, or
          high-dynamic-range content.
        </li>

        <li>
          {" "}
          Geographic variability: Due to reasons including but not limited to
          restrictions imposed by the content providers, the Platform is
          available in select locations only. The Content available to you shall
          vary from time to time due to such geographic restrictions. You agree
          to not access the Platform by means of any technology which
          masks/disguises or conceals your actual geo-location or provides
          incorrect details of your location (e.g., virtual private networks/
          VPNs) and/ or outside the Territory.
        </li>

        <li>
          <ol>
            Platform and its Content usage: You acknowledge that the Content
            made available to you on the Platform is protected by Intellectual
            property rights. For the purposes of this Agreement, “Intellectual
            Property Rights” shall mean any and all copyrights, trademarks,
            patents, geographical indications, design rights, trade names,
            software, domain name, technology, source code, database, titles,
            animation, servers, applications, interactive elements, data, brand
            names of the Platform, proprietary rights of Boxofic and/ or its
            respective licensors and/ or respective owners. You agree to respect
            and abide by all applicable laws and regulations regarding the use
            of copyrighted Content. The Platform retains all rights, title,
            interest in and to the Platform including but not limited to all the
            Intellectual property rights therein. Any unauthorized use,
            reproduction, or distribution of Content may be subject to legal
            consequences. The Content shall be available/ accessible to you via
            two methods:
            <ol>
              <li>
                {" "}
                Digital rental and duration: You may avail/ access such Content
                on the Platform on a digital rental basis pursuant to payment of
                such amount as stated and determined on the Platform (“Rental
                Fee”) and you shall typically watch it within the Viewing Period
                (defined below) for such Content as stated on the Platform. The
                Rental Fee for any and all Content may be
                modified/amended/changed depending on various circumstances and
                subject to and by the content provider, without prior notice to
                you. The Rental Fee shall not change once paid for by you for
                the Viewing Period. You agree and confirm that may stream the
                same video to no more than 1 (one) device at a time and further,
                you acknowledge that there shall be no natural repeat and/ or
                re-watch of the particular Content after successful 1 (one) time
                streaming of the rental Content.
                <br />
                For clarity, Viewing Period: For digital rental/ pay—per-view
                content, the Viewing Period shall be a period not exceeding 36
                (thirty-six) hours from the time you begin to stream the Content
                and however no later than 7 (seven) days from date of rental
                purchase of such pay-per-view Content. If you have any dispute
                with us regarding the Content expiring before 36 hours, you may
                raise a complaint via the order history section. To do this,
                please go to the website or app where you purchased the content
                and click on the "Order History" section. Find the order for
              </li>
              <li>
                Ad-supported content: In the event you do not wish to pay the
                Rental Fee for ad-free access to the Content, the same shall be
                available to you cost-free in the method of adsupported Content.
                Such ad-supported content may include display ads, pre-roll ads,
                mid-roll ads, commercial messages, or interruptions (whether
                display, interstitial or otherwise) or any other format of
                advertising as per the policies, guidelines, regulations and
                sole discretion of the Platform. Further, the quality of
                streaming for such adsupported Content shall be solely
                determined by the Platform at its sole discretion.
              </li>
            </ol>
            <li>
              Third party materials: Certain services on the Platform may
              display, include or make available content, data, information,
              applications, advertisements of various forms including but not
              limited to display ads, pre-roll ads, mid-roll ads, or any other
              format of advertising or materials from third parties (“Third
              Party Materials”) or provide links to certain third party web
              sites. By using the Platform, you acknowledge and agree that the
              Platform is not responsible for examining or evaluating the
              content, accuracy, completeness, authenticity, validity, copyright
              compliance, legality, decency, quality or any other aspect of such
              Third Party Materials or web sites. The Platform, its officers,
              affiliates and subsidiaries do not warrant or endorse and do not
              assume and will not have any liability or responsibility to you or
              any other person for any third-party Services, Third Party
              Materials or web sites, or for any other materials, products, or
              services of third parties. Third Party Materials and links to
              other web sites are provided solely as a convenience to you.
            </li>
          </ol>
        </li>

        <li>
          Digital Rental and payments: To facilitate your viewership and access,
          the Platform can be made available to you through different models and
          may be accessible (a) free of charge which may include advertisements
          and/ or commercials in various forms; or (b) a pay-per view/ digital
          rental model for particular Content or without advertisements/
          commercials (“Digital Rental”); or (c) Digital Rental or ad-supported
          access for particular Content in 4k; or (d) with a combination of any,
          or all of the foregoing
          <ol>
            <li>
              Payment/ Digital Rental: Upon successful creation of a valid
              Account, the Viewer shall be required to pay a Rental Fee subject
              to the Digital Rental plan provided by the Platform and availed by
              the Viewer, from time to time. Depending upon the Digital Rental
              plan chosen by the Viewer for the Rental Fee, the Rental Fee shall
              vary, from time to time. Upon selection of any Digital Rental plan
              under ‘Rent Now’, the Viewer shall be directed to a payment
              getaway/ third party aggregator for payment of the Rental Fee
              which Rental Fee shall be deposited in the Wallet (defined below)
              and upon receipt of successful payment of Rental Fees by the
              Platform via the Wallet, the Viewer shall be able to access
              specific premium content as part of Digital Rental. In the event
              of any change in the Digital Rental plan availed by you and/ or
              the Digital Rental plan availed by you is about to expire, the
              Platform may notify you in advance about any such development
              whether via a notification on email and/ or registered mobile
              number. For clarity, upon cancellation, if the Viewer would like
              to re-rent or avail of any other Digital Rental plan, the same
              process for any Digital Rental shall apply.
            </li>
            <li>
              Offers and pricing for Digital Rental(s), the subscription
              services, the extent of Content available for Digital Rental,
              etc., may change over time and by location without notice. Unless
              otherwise indicated, any and all pricing change in relation to the
              Digital Rental shall be effective upon the next Digital Rental
              cycle. In the event the Platform is unable to process the payment
              using your designated Payment Method, the Platform reserves the
              right to charge any payment method we have on file for you. For
              the purpose of this Agreement, “Payment method” shall mean a valid
              and active payment method which includes (i) recurring payment
              options like major credit cards and major debit cards; (ii)
              one-time payment options like UPI/ net banking/ prepaid/ credit
              and debit cards which do not fall under recurring payment options;
              and (iii) any other methods that are approved by the Platform,
              from time to time. In the event all the payment methods are unable
              to be processed, the Platform shall cancel your Digital Rental for
              that particular Content effectively unless provided with a new
              payment method. The Platform, at its sole discretion, may offer
              the Viewer certain discounts, concessions, markdown in the Rental
              Fee of Content, if applicable and from time to time, depending
              upon and subject to the funds deposited/ present/ existing in the
              Boxofic wallet (“Wallet”) available in the Account of the Viewer
              on the Platform. Any and all discounts offered by the Platform
              shall be at its sole discretion and not an obligation on the
              Platform by any means whatsoever
            </li>

            <li>
              . Payment: The Digital Rental shall become available to access
              upon payment of the Rental Fee and in certain cases, the payment
              may take longer to process due to operational reasons. The Rental
              Fee shall be inclusive of all applicable taxes. For the purposes
              of Rental Fee payment process, the Viewer shall be required to
              provide a Payment Method authorised/ accepted by the Platform and/
              or Boxofic. Upon receipt of payment and access the Platform by
              you, the Platform shall share an electronic invoice for such
              Rental Fees paid. You agree and acknowledge to use the Payment
              Methods at your discretion upon knowledge that some Payment Method
              service providers might charge a transactional fee. The Payment
              Methods shall be changed, updated, modified by the Platform at its
              sole discretion, from time to time. For clarification, the Digital
              Rental plan shall become active upon the Platform’s receipt of the
              Rental Fees. In the event the primary Payment Method fails for
              whatever reason, the Platform reserves the right, at its sole
              discretion, to (i) charge the payment to an alternate Payment
              Method, if any; or (ii) change the nature of Content from a
              digital rental/ pay-per-view model of Digital Rental to
              ad-supported access with change in video quality.
            </li>

            <li>
              You agree and acknowledge that the Platform shall exclusively use
              a third party aggregator for all payment purposes and
              consequently, certain payment information including but not
              limited to banking information shall be shared with such third
              party for the smooth processing of payments. Save and except a
              receipt of confirmation of the Rental Fee, the Platform disclaims
              any and all liabilities in relation to payment processing/
              payments via a third party aggregator and the information
              collected by the third party payment processor. For abundant
              clarity, the Platform does not use and/ or collect information
              related to payments including but not limited to bank account
              number, one-time-passwords, credit/ debit card number, etc., and
              shall not be responsible or liable for any and all misuse of such
              information by anyone. You agree and acknowledge that to the
              extent permitted under law, the Platform shall not be responsible
              or liable for any direct, consequential, inconsequential, indirect
              losses and damages caused due to usage and/ or delay in processing
              of payments in connection with the Platform.
            </li>

            <li>
              The Platform reserves the right to change/ modify/ terminate the
              Digital Rental plans, Digital Rental term, Rental Fees, payment
              cycles including the ad-supported access and subscription packages
              at any time at its sole discretion. In the event of any change to
              the aforesaid, the same shall be effective upon being the viewer
              being active on the Platform. Your continued use of the Platform
              pursuant to such changes shall be an agreement by you for the
              changes. If the Viewer does not agree to the new and updated
              terms, the exclusive recourse to the Viewer shall be to cancel the
              Digital Rental. Further, the Rental Fees paid by you shall be
              non-refundable under any circumstances and Platform shall not be
              liability to refund any monies paid by you to the Platform for any
              reason including but not limited to partially utilised Digital
              Rental during the Digital Rental term, cancellation of the Digital
              Rental, etc., to the extent permitted under law. The Viewer cannot
              and shall not be able to change the Digital Rental during an
              on-going Digital Rental.
            </li>
            <li>
              . The Platform shall have the right and discretion but not
              obligation to make the Content that is part of the Digital Rental
              plan/ ad-supported access availed and accessible to you on more
              than 1 (one) Compatibility System simultaneously. The Platform
              reserves the right to change, amend, modify, remove, supplement
              the Content forming part of a Digital Rental/ ad-supported access
              at its sole discretion. The Platform further reserves the right,
              with or without notice, to (i) remove or withdraw any Digital
              Rental plan, whether availed by the Viewer or not; (ii) change,
              withdraw, remove, replace any Content; (iii) modify the prices/
              Digital Rental plan to avail the relevant Content and/ or modify
              any part of the service; (iv) introduce/ add-on new Digital Rental
              plans/ packages to access Content on an additional fee basis and/
              or a la carte basis. The Platform shall be under no liability for
              the foregoing.{" "}
            </li>
          </ol>
        </li>

        <li>
          Advertisements: By agreeing to the terms and conditions under this
          Agreement, you agree to receive advertisements from us and our
          partners. These advertisements may be displayed on our website, in our
          emails, or in other ways.
        </li>
        <li>
          Refund policy: We regret to inform you that all purchases made on our
          VOD service are non-refundable. Once a video is rented or purchased,
          we do not offer refunds under any circumstances. In the event of
          technical issues preventing access to the purchased content, please
          contact our customer support promptly. We will make reasonable efforts
          to address and resolve technical problems, which may include providing
          an alternative method to access the content.
        </li>

        <li>
          Beta Services: The Platform may invite you to try beta, pilot, or
          limited release features (“Beta Services”). By using any Beta
          Services, you agree that (a) Beta Services are not intended for
          commercial usage, may be modified or discontinued at any time, may
          contain bugs or errors, and are not subject to uptime, support, or
          other service level commitments; and (b) you will provide timely
          feedback to the Platform and only use the Beta Services for the
          purpose of providing such feedback.
        </li>

        <li>
          Intellectual Property Rights: All the rights, title and interest in
          the Platform and Content including without limitation to all its
          constituents, audio, video, images, layout, icons, etc., are the
          property of the Platform and/ or its respective owners under the
          applicable laws of India and the world. The Platform retains and
          reserves all titles in and to the Intellectual Property Rights in
          perpetuity around the world and for all languages. You agree,
          acknowledge and confirm that the Platform is the owner of all
          Intellectual property rights, including all copyrights, patents,
          trademarks, associated with the Platform, including all associated
          software, text, graphics, and logos, but exclusive of Content provided
          by third parties, which are owned by licensors of such Content and/ or
          their respective owners.
        </li>

        <li>
          <ol>
            <li>
              {" "}
              Viewer material: The Platform may allow Viewers to publish
              content, information, pictures, data, etc., either developed by
              the Viewer on their own and/ or via any pre-existing template
              and/or other materials provided by the Platform (“Viewer
              material”). You agree and acknowledge that upon posting any Viewer
              material about yourself, the said Viewer material may be posted
              publicly on the Platform. For clarity, the Platform does not
              endorse any such Viewer material and the views surrounding the
              Viewer material are not the views of the Platform and further, the
              Platform is not responsible for any Viewer material and shall not
              be held liable for any damages, losses and claims arising out of
              the Viewer material. The Platform may ask/ permit the Viewers to
              publish reviews of the Platform/ Content and any part thereof and
              you agree that any such review(s) posted/ published by you shall
              be in good faith and of your true thoughts/ opinions/ values and
              devoid of any propaganda/ agenda/ falsities/ false language/
              abusive language/ derogatory and libelous remarks/ slander/hurting
              the sentiments of any religious sect, community or people. The
              Platform may use manual or automated systems to track, moderate,
              screen the Viewer material and may in its sole discretion amend,
              change, remove, edit, modify, change the Viewer material as it
              deems fit. You agree and confirm that such decision of the
              Platform shall be binding on you and you shall have no claims
              regarding such discretion of the Platform. You agree and
              acknowledge that the Viewer material shall: i) respect the rights
              of others, including privacy; (ii) not include third-party
              intellectual property (such as copyrighted material) unless you
              have permission from that party or am otherwise legally entitled
              to do so; (iii) not abuse or harm others or yourself (or threaten
              or encourage such abuse or harm), including against children; (iv)
              not mislead, be patently false, or defrauding; (v) not illegally
              impersonate, defame, bully, harass, be obscene or stalk others;
              and (vi) not incite violation of applicable laws; B
            </li>
            <li>
              . Name and likeness of the Viewer: By publishing Viewer material,
              you agree and grant the Platform and its assignees and licensees a
              worldwide, perpetual, irrevocable, royalty-free license to use and
              authorise others to use the Viewer material in whole or in part
              for in any manner and on any mode, media whatsoever, by itself or
              in conjunction with other material for any purposes including
              commercial exploitation and/ or promotion. In the event of any
              commercial exploitation of the Viewer material, you agree and
              confirm that you are not entitled to any intimation by the
              Platform and/ or compensation (monetary or otherwise) for the
              same. You agree and confirm that the Platform is not under any
              obligation to use, host, display and distribute the Viewer
              material upon grant of the aforesaid license.{" "}
            </li>
          </ol>
        </li>

        <li>
          Holdbacks/ restrictions on use of Platform and the Content: In using
          the Platform, you agree to not, directly or indirectly:
          <ul>
            <li>
              Modify, reproduce, distribute or create derivative works based on
              the Platform or any part thereof;
            </li>
            <li>
              Use the Platform for any commercial purpose without the prior
              written consent of the Platform;
            </li>
            <li>
              Remove, alter, or obscure any proprietary notices or labels on the
              Platform;
            </li>
            <li>
              Use the Platform in any manner that violates applicable laws,
              regulations, or third-party rights;
            </li>
            <li>
              . Use the Platform to upload, transmit, or distribute any content
              that is unlawful, harmful, abusive, defamatory, or infringing;
            </li>
            <li>
              Use the Platform in any manner that could disable, overburden, or
              impair the functionality or security of the Platform or interfere
              with other users’ access to the Platform;
            </li>
            <li>
              Use any automated system, including “bots”, “spiders”, or
              “crawlers” to access or interact with the Platform;
            </li>
            <li>
              Use the Platform or any part thereof to store/import/ download/
              transfer/ lease/ rent/ resell/ distribute any Content (whether
              electronically or otherwise) available on the Platform in present
              or in the past for any reason whatsoever by any means
            </li>
            <li></li>
            <li>
              Use an offensive screen name (e.g., explicit language) or avatar
              (e.g., containing nudity);
            </li>
            <li>
              Act in a deceptive manner or impersonate any person or
              organization;
            </li>
            <li>
              Create any lien, charge and/ or any encumbrances of any nature on
              the Platform, Content or any part thereof; and
            </li>
            <li>
              You agree and acknowledge that you shall not broadcast the
              Platform and its Content and are restrained from public
              communication of any Content therein including but not limited to
              hotels, bars, libraries, schools, stadiums, resorts, spa, etc.,
              wherein there may or may not be a commercial arrangement or any
              nature whatsoever. For clarity, you specifically exclude the
              public use/ communication, exhibition of the Platform in any
              manner whatsoever. You agree to not permit any third party to
              benefit from the use of the Platform, whether directly or
              indirectly and in any manner whatsoever. Without prejudice to
              rights available to the Platform under law, the Platform shall
              immediately upon notice of such public and forbidden communication
              of the Content terminate/ suspend the Account of the Viewer
              without any refund of the Rental Fee.
            </li>
            You agree that you will not use such proprietary content,
            information or materials other than for permitted use of the
            Platform or in any manner that is inconsistent with the terms of
            this Agreement or that infringes any Intellectual property rights of
            a third party or the Platform.
          </ul>
        </li>

        <li>
          Term/ termination: This Agreement shall be valid from the date of you
          agreeing to this Agreement save and except in the event of termination
          by the Platform and/ or you. If you decide not to access the Platform
          anymore, any charges/ monies paid by you towards the using/ accessing
          the Platform shall be non-refundable. The Platform shall have the
          right to suspend/ deny/ de-activate the Account/ limit your access to
          the Platform, or terminate this Agreement and your access to the
          Platform and/ or exercise any remedies available to the Platform under
          law, at its sole discretion, with or without cause for either: (i)
          breach (alleged, threatened, actual) by you of any of the terms of
          this agreement; (ii) failure of payment method; or (iii) any reason
          that the Platform deems appropriate due to including but not limited
          to inappropriate/ false/ defamatory behaviour by the Viewer in
          relation to user reviews. Upon termination the Platform shall not be
          liable to refund any amounts paid by You to the Platform. Upon
          termination, you shall cease all use of the Platform and any
          provisions of this Agreement by their nature, should survive
          termination, shall survive, including but not limited to limitation of
          liability, indemnity, etc.
        </li>

        <li>
          . Limitation of liability: TO THE EXTENT NOT PROHIBITED BY APPLICABLE
          LAW, IN NO EVENT SHALL THE PLATFORM, ITS AFFILIATES, AGENTS, OR
          PRINCIPALS BE LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL,
          INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT
          LIMITATION, CORRUPTION OR LOSS OF DATA, FAILURE TO TRANSMIT OR RECEIVE
          ANY DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR
          LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
          PLATFORM AND SERVICES OR ANY THIRD PARTY SOFTWARE OR APPLICATIONS IN
          CONJUNCTION WITH THE PLATFORM OR SERVICES, HOWEVER CAUSED, REGARDLESS
          OF THE THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF
          THE PLATFORM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
          THIS LIMITATION MAY NOT APPLY TO YOU. The foregoing limitations will
          apply even if the above stated remedy fails of its essential purpose.
          <br />
          <br />
          In no event shall the Platform’s total liability for any and all
          losses, damages, claims, of whatever nature (WHETHER IN CONTRACT,
          TORT, BREACH OF STATUTORY DUTY OR OTHERWISE), including under the
          privacy policy, exceed the amount paid by you, if any, for assessing
          the service provided, however the maximum liability of the Platform in
          all instances shall be limited to INR 100 (Indian Rupees One Hundred
          only).
          <br />
          <br />
          You agree and confirm that the Platform shall not be liable in the
          event of non-availability of Content on the Platform or any part
          thereof, occasioned by any act of God or force majeure event, act of
          public enemy, war or warlike operations, rebellions, sabotage,
          mobilisations or military-call ups, disease, revolution, riot, civil
          commotion, strikes, pandemics and other labour difficulties,
          quarantine restrictions, freight embargoes, shortage of raw materials,
          lockout, flood, fire, satellite/ internet failure, failure of any
          public utility, man-made disaster, network maintenance, server
          maintenance, Platform maintenance, or any other cause beyond the
          control of the Platform. You acknowledge and confirm that this clause
          shall survive the termination or expiry of this Agreement.
        </li>

        <li>
          . Indemnification: You agree to indemnify, defend and hold harmless
          the Platform from any claims, damages, liabilities, costs or expenses
          (including attorney fees) arising out of or in connection with your
          use of the Platform, violation of any of the terms of this Agreement,
          or infringement of rights of any third party.{" "}
        </li>

        <li>
          Miscellaneous
          <ul>
            <li>
              Governing law: This Agreement shall be governed by and construed
              in accordance with the laws of India and the Courts at New Delhi
              shall have exclusive jurisdiction regarding any matter arising out
              of or related to this Agreement.
            </li>
            <li>
              You agree that you shall not assign any rights granted to you
              under this Agreement including the obligations to any third party.
              You agree and acknowledge the sublicense of any rights granted by
              you to the Platform in relation to the providing services related
              to the Platform and you confirm that such affiliates of the
              Platform shall have the same rights granted by you to the Platform
              under this Agreement.
            </li>
            <li>
              Each affiliate of the Platform is severally liable for its own
              obligations under this Agreement and is not jointly liable for the
              obligations of other affiliates of the Platform. In addition, each
              affiliate of the Platform is solely responsible with respect to
              its exercise of its rights and compliance with its obligations in
              connection with the territory or territories for which it is
              responsible, as determined by the Platform in its sole discretion.
            </li>
            <li>
              Nothing herein contained shall be construed to create a
              partnership, joint venture, association of persons, agency, or
              employment agreement between the parties hereto.
            </li>
            <li>
              If any provision of this Agreement is adjudged by a court to be
              void or unenforceable, the same shall in no way affect any other
              provision of this Agreement, or its validity or enforceability and
              the unenforceable provision shall be performed to the extent valid
              and enforceable.
            </li>
            <li>
              In the event of any conflict between the terms of this Agreement
              and any and all notifications/ banners/ pop-ups/ notices on the
              Platform, such notifications/ banners/ pop-ups, etc., shall
              prevail to the extent of such conflicting terms.
            </li>
            <li>
              Any notices to the Viewer shall be in writing and shall be
              addressed via an email to the registered email id, a message to
              the registered mobile number, notifications, banners, pop-ups,
              etc., on the Platform. In the event of any conflict between the
              terms of this Agreement and any and all notifications/ banners/
              pop-ups/ notices on the Platform, such notifications/ banners/
              pop-ups, etc., shall prevail to the extent of such conflicting
              terms.
            </li>
          </ul>
        </li>
        <li>
          Amendment to this Agreement: In the event of any material change to
          this Agreement, at the sole discretion of the Platform, you agree and
          confirm that in the event any changes to the Platform are not
          agreeable by you, you shall terminate the Agreement and withdraw your
          access to the Platform.
        </li>

        <li>
          Notices from the Platform: If the Platform needs to contact you about
          your Account and/ or your access thereof, you consent to receive the
          notices by email and/ or your registered mobile number. You agree that
          any such notices that we send you electronically will satisfy any
          legal communication requirements.
        </li>

        <li>
          {" "}
          Support: If you have any complaints regarding the Platform, your
          access to the Platform, the Content, you may please avail the support
          and assistance of the support chat available on the Platform under
          ‘support’. In the event your query is not resolved via the support
          chat, you may please contact the Platform at support@halliv.com. If
          you have any information, data, complaint, feedback regarding any of
          the Content of the Platform infringing upon any applicable laws,
          please reach out to us at support@halliv.com Please refrain from
          frivolous claims and/ or misinformation as such notices are considered
          in a serious manner and you could bear legal consequences and/ or
          suspension/ termination of your account. Please note that such
          communication by you to the Platform shall only result in taking down
          any content upon a valid court decree from the relevant government
          organisation/ agency and upon following the applicable procedure and
          laws. The Platform reserves the right to take any action, however not
          under any obligation, in relation to such complaints at its sole
          discretion.{" "}
        </li>

        <li>
          {" "}
          Privacy policy: The Privacy Policy (as provided on the Platform), and
          any other documents, instructions, etc. included on the Service shall
          be read into this and shall be a part of these Agreement. The Privacy
          Policy shall form an integral part of the Agreement and both these
          documents constitute the user agreement and a legally binding contract
          between HallIV and the Viewer.
        </li>
      </ol>
    </div>
  );
};

export default TermAndConditons;
