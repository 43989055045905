import React, { useEffect, useRef, useState } from "react";
import styles from "./TopNavBar.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HallIV_logo from "../../../assets/svgs/HallIVLogo.svg";

import { Dropdown, NavDropdown } from "react-bootstrap";
import API from "../../../utils/apiService";

const TopNavBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // get the selected catgeory from url slug
  const { category_slug } = useParams();
  // getting dimensions use ref
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const q = urlParams.get("q");
  const [searchText, setSearchText] = useState(q || "");
  const inputRef = useRef(null);

  const handleClick = (category) => {
    setShow(false);
    // toggleOpen();
    setIsOpen(true);
    navigate(`/browse/${category}`);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleKeyDown = (e) => {
    if (searchText.trim() == "") {
      return;
    }
    if (e.key === "Enter") {
      navigate(`/browse?q=${searchText}`);
      setSearchText("");
    }
  };
  // handling search
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText.trim() == "") {
      return;
    }
    navigate(`/browse?q=${searchText}`);
    setSearchText("");
  };

  useEffect(() => {
    if (q) {
      setSearchText(q);
    } else {
      setSearchText("");
    }
  }, [q]);

  // handle logout
  const handleLogout = async () => {
    try {
      API.postAuth("api/viewerApp/logOut_v2");
      setTimeout(() => {
        localStorage.clear();
        window?.flutter_inappwebview?.callHandler("logOutHandler", "");
        navigate("/signin", { replace: true });
      }, 1000);
    } catch (error) {
      window?.flutter_inappwebview?.callHandler("logOutHandler", "");
      localStorage.clear();
      navigate("/signin");
    }
  };

  return (
    <div className={styles.topNavBar_container}>
      <div className={styles.topNavBar_container_logo}>
        <NavLink to={"/browse/all"}>
          <img src={HallIV_logo} />
        </NavLink>
      </div>
      <div className={styles.topNavBar_container_slugs}>
        <div className={styles.topNavBar_container_slugs_box}>
          <span
            className={
              category_slug == "all"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
            onClick={() => handleClick("all")}
          >
            {t("All")}
          </span>

          <span
            className={
              category_slug == "movie"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
            onClick={() => handleClick("movie")}
          >
            {t("Movies")}
          </span>

          <span
            className={
              category_slug == "documentary"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
            onClick={() => handleClick("documentary")}
          >
            {t("Documentaries")}
          </span>

          <span
            onClick={() => handleClick("tvSeries")}
            className={
              category_slug == "tvSeries"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
          >
            {t("Tv Series")}
          </span>

          <span
            onClick={() => handleClick("miniSeries")}
            className={
              category_slug == "miniSeries"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
          >
            {t("Mini-series/ Shorts")}
          </span>

          <span
            onClick={() => handleClick("podcast")}
            className={
              category_slug == "podcast"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
          >
            {t("Podcasts")}
          </span>

          <span
            onClick={() => handleClick("standUp")}
            className={
              category_slug == "standUp"
                ? styles.topNavBar_container_slugs_selected_item
                : ""
            }
          >
            {t("Stand up")}
          </span>
        </div>
      </div>
      <div className={styles.topNavBar_container_menu}>
        <div className={styles.topNavBar_container_menu_input}>
          <form onSubmit={(e) => handleSearch(e)}>
            <input
              ref={inputRef}
              required
              type="text"
              className={`${styles["search-input"]} ${
                isSearchOpen ? styles.active : ""
              }`}
              placeholder="Search..."
              onKeyDown={handleKeyDown}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <svg
              onClick={() => {
                setIsSearchOpen(!isSearchOpen);
                inputRef.current?.focus();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 34 34"
              fill="none"
              style={{ cursor: "pointer" }}
            >
              <path
                d="M29.75 29.75L22.388 22.388M22.388 22.388C24.3108 20.4653 25.5 17.809 25.5 14.875C25.5 9.00697 20.743 4.25 14.875 4.25C9.00697 4.25 4.25 9.00697 4.25 14.875C4.25 20.743 9.00697 25.5 14.875 25.5C17.809 25.5 20.4653 24.3108 22.388 22.388Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </form>
        </div>
        <div className={styles.topNavBar_container_menu_icon}>
          <div>
            <NavDropdown
              title={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 0C7.6109 0 0 7.6109 0 17C0 26.3891 7.6109 34 17 34C26.3891 34 34 26.3891 34 17C34 7.6109 26.3891 0 17 0ZM11.05 12.75C11.05 11.9686 11.2039 11.1949 11.5029 10.473C11.8019 9.75115 12.2402 9.09522 12.7927 8.54271C13.3452 7.99021 14.0011 7.55193 14.723 7.25292C15.4449 6.9539 16.2186 6.8 17 6.8C17.7814 6.8 18.5551 6.9539 19.277 7.25292C19.9989 7.55193 20.6548 7.99021 21.2073 8.54271C21.7598 9.09522 22.1981 9.75115 22.4971 10.473C22.7961 11.1949 22.95 11.9686 22.95 12.75C22.95 14.328 22.3231 15.8414 21.2073 16.9573C20.0914 18.0731 18.578 18.7 17 18.7C15.422 18.7 13.9086 18.0731 12.7927 16.9573C11.6769 15.8414 11.05 14.328 11.05 12.75ZM27.6386 25.4728C26.366 27.0736 24.7483 28.3661 22.9061 29.2539C21.0639 30.1418 19.045 30.6019 17 30.6C14.955 30.6019 12.9361 30.1418 11.0939 29.2539C9.25174 28.3661 7.63396 27.0736 6.3614 25.4728C9.1171 23.4957 12.8775 22.1 17 22.1C21.1225 22.1 24.8829 23.4957 27.6386 25.4728Z"
                    fill="white"
                  />
                </svg>
              }
              className={`custom-nav-dropdown`}
            >
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/profile")}
              >
                {t("Profile")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/orderhistory")}
              >
                {t("Transaction History")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/favourites")}
              >
                {t("Favourites")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/watch-history")}
              >
                {t("Review and Rating")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/invite-users")}
              >
                {t("Refer & Win")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={() => handleNavigate("/support")}
              >
                {t("Support")}
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.topNavBar_container_menu_icon_dropdow_item}
                onClick={handleLogout}
              >
                {t("Logout")}
              </Dropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
