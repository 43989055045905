import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from "./App";

if (process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "prod") {
  //  console.log(process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT);
  console.log = () => {};
  console.warn = () => {};
  console.debug = () => {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
