import React from "react";
import { Outlet } from "react-router-dom";
import AuthFooter from "../../components/footerForAuth/AuthFooter";
import Styles from "./AuthLayout.module.css";

const AuthLayout = () => {
  return (
    <div className={Styles.Auth_Layout_container}>
      <div className={Styles.Auth_Layout_container_outlet}>
        <Outlet />
      </div>
      <AuthFooter />
    </div>
  );
};

export default AuthLayout;
