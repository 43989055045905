import React, { useEffect, useState } from "react";
import styles from "./TicketCard.module.css";
import { useTranslation } from "react-i18next";
import ResumePlay from "../resumePlay/ResumePlay";
import { checkPaymentStatusForEpisodes } from "../../utils/checkContentPurchaseStatus";
import { handleClickTicket } from "../../utils/handleClickTicket";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TicketCard = ({
  PricesGroups,
  PricesIndividuals,
  paymentStatus,
  seasonNumber,
  episodeNumber,
  isSeries,
}) => {
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setPaymentDetails(
      checkPaymentStatusForEpisodes(
        paymentStatus,
        isSeries,
        seasonNumber,
        episodeNumber
      )
    );
  }, [paymentStatus]);

  const handlePurchase = () => {
    handleClickTicket({ isSeries, seasonNumber, episodeNumber, navigate });
  };

  return (
    <div className={styles.ticket_card_container}>
      {paymentDetails && (
        <ResumePlay
          {...paymentDetails}
          isSeries={isSeries}
          episodeNumber={episodeNumber}
        />
      )}
      <div className={styles.tickets}>
        <h5>{t("Buy Tickets")}</h5>
        <div className={styles.tickets_body}>
          <OverlayTrigger
            key={"bottom"}
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip-${"bottom"}`}>
                {t(
                  "The Individuals feature is available only on the app's. To access it, please download the app from the Google Play Store."
                )}
              </Tooltip>
            }
          >
            <button
              disabled
              className={styles.tickets_body_left + ` ${styles.disabled}`}
            >
              <div className={styles.tickets_body_left_header}>
                <label>{t("For Individuals")}</label>
              </div>
              <div>
                <label>
                  <span>Rs {PricesIndividuals}</span>/per person
                </label>
              </div>
            </button>
          </OverlayTrigger>
          <button
            onClick={handlePurchase}
            disabled={paymentDetails}
            className={
              styles.tickets_body_right +
              ` ${paymentDetails ? styles.disabled : ""}`
            }
          >
            <div className={styles.tickets_body_right_header}>
              <label>{t("For Groups")}</label>
            </div>
            <div>
              <label>
                <span>Rs {PricesGroups}</span>/per person
              </label>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
