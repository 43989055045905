import React, { useEffect, useState } from "react";
import styles from "./TicketModal.module.css";
import { Modal, Button } from "react-bootstrap";
import { t } from "i18next";
import Confetti from "react-confetti";

const TicketModal = ({
  show = false,
  handleClose = () => {},
  movieTitle = "Demo Movie",
  viewers = ["John Doe", "Jane Doe"],
  date = { date: "2025-03-01", time: "7:00 PM" },
}) => {
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-CA", options).replace(/\//g, "-");
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const now = new Date();

  date = {
    date: formatDate(now),
    time: formatTime(now),
  };

  // Format viewer names
  let displayedViewers = "";
  if (viewers.length > 2) {
    displayedViewers = `${viewers[0]}, ${viewers[1]} & ${
      viewers.length - 2
    } others`;
  } else {
    displayedViewers = viewers.join(", ");
  }

  useEffect(() => {
    setIsConfettiActive(show);
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        className={styles.ticketBox_modal}
        onHide={handleClose}
        fullscreen
      >
        <Modal.Body>
          <div className={styles.ticketContainer}>
            <div className={styles.ticketBox}>
              <div className={styles.ticketCard}>
                <div className={styles.ticketDetails}>
                  <label className={styles.movieTitle}>{movieTitle}</label>
                  <label className={styles.playingText}>Playing in</label>
                  <label className={styles.hall}>HALL IV</label>

                  <p className={styles.viewers}>{displayedViewers}</p>

                  <div className={styles.dateTime}>
                    <span className={styles.date}>{date.date}</span>
                    <span className={styles.time}>{date.time}</span>
                  </div>
                </div>
              </div>
              <div className={styles.playButton}>
                <button onClick={handleClose} className={styles.playLabel}>
                  {t("Continue")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        {isConfettiActive && (
          <Confetti
            className={styles.ticketBox_confetti}
            recycle={false} // Ensures confetti doesn't loop infinitely
            numberOfPieces={500} // Adjust number of confetti pieces
            colors={["#FFD700", "#FFAA00", "#FFC300"]} // Gold shades
          />
        )}
      </Modal>
    </>
  );
};

export default TicketModal;
