import React, { useEffect, useState } from "react";
import styles from "./GroupPurchase.module.css";
import hand from "../../assets/images/hand.png";
import { useTranslation } from "react-i18next";
import API from "../../utils/apiService";
import { groupPurchaseDetails } from "../../utils/groupPurchaseDetails";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { durationConverter } from "../../utils/durationConverter";
import {
  getHomeDataSuggestedForYouError,
  getHomeDataSuggestedForYouReq,
  getHomeDataSuggestedForYouSuccess,
} from "../../redux/homeReducer/action";
import { useDispatch, useSelector } from "react-redux";
import renderEpisodesInCarousel from "../../utils/renderEpisodesInCarousel";
import CarouselSkeleton from "../../components/Skeletons/CarouselSkeleton";
import ListCarousel from "../../components/carousels/carousel/ListCarousel";
import GorupPurchaseSkeleton from "../../components/Skeletons/GorupPurchaseSkeleton";
import { getDeviceType } from "../../utils/getDeviceType";

const GroupPurchase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { video_id } = useParams();
  const [videoDetails, setVideoDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seasonNumber, setSeasonNumber] = useState(
    parseInt(sessionStorage.getItem("seasonNumber")) || 1
  );
  const [episodeNumber, setEpisodeNumber] = useState(
    parseInt(sessionStorage.getItem("episodeNumber")) || null
  );
  const [isSeries, setIsSeries] = useState(false);
  const [category_slug, setCategory_slug] = useState("all");
  const [suggestedVideoLoading, setSuggestedVideoLoading] = useState(true);
  const { trendingData, continueWatchingData, whatsNewData, suggestedData } =
    useSelector((state) => state?.homeReducer);

  const getVideoDetailsById = async () => {
    setLoading(true);
    try {
      let response = await API.get(`getVideoDetailsById/${video_id}`);

      if (response?.status) {
        let priceType;
        if (response?.data?.isSeries) {
          if (seasonNumber && episodeNumber) {
            priceType = "watchPartyPerEpisode";
          } else {
            priceType = "watchPartySeason";
          }
        } else {
          priceType = "watchParty";
        }

        let details = groupPurchaseDetails(
          response?.data?.isSeries,
          seasonNumber,
          episodeNumber,
          response?.data,
          priceType,
          getDeviceType()
        );
        console.log("data", details);

        setVideoDetails(details);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSuggestedVideo = async () => {
    if (suggestedData[category_slug]?.length >= 1) {
      setSuggestedVideoLoading(false);
      return;
    }
    dispatch(getHomeDataSuggestedForYouReq(category_slug));
    setSuggestedVideoLoading(true);

    try {
      let response = await API.get(
        `suggestedVideoForViewer?category=${category_slug}`
      );
      if (response?.status) {
        let video = renderEpisodesInCarousel(response?.data?.videos);

        setSuggestedVideoLoading(false);
        dispatch(
          getHomeDataSuggestedForYouSuccess({
            data: video,
            slug: category_slug,
          })
        );
      }
    } catch (error) {
      setSuggestedVideoLoading(false);
      dispatch(getHomeDataSuggestedForYouError(category_slug));
    }
  };

  const handleMoreOptions = () => {
    navigate(`/details/${video_id}/group-purchase-payment`, { replace: true });
  };

  useEffect(() => {
    getVideoDetailsById();
    getSuggestedVideo();
  }, []);
  return (
    <div className={styles.group_purchase_container}>
      {!loading && videoDetails ? (
        <div className={styles.group_purchase_container_details}>
          <div className={styles.group_purchase_container_details_top}>
            <div className={styles.group_purchase_container_details_top_left}>
              <img src={videoDetails?.backgroundImage} />
            </div>
            <div className={styles.group_purchase_container_details_top_right}>
              <div>
                <h4>{(videoDetails && videoDetails?.title) || ""}</h4>
              </div>
              <div
                className={
                  styles.group_purchase_container_details_top_right_duration
                }
              >
                <label>{durationConverter(videoDetails?.duration)}</label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <circle cx="2" cy="2" r="2" fill="white" />
                </svg>
                <label>{videoDetails?.genre?.join("/ ")}</label>
                <div>{videoDetails?.cbfc_Rating}</div>
              </div>
              <div
                className={
                  styles.group_purchase_container_details_top_right_description
                }
              >
                <p>{videoDetails?.synopsis ? videoDetails?.synopsis : ""}</p>
              </div>
              <div
                className={
                  styles.group_purchase_container_details_top_right_policy
                }
              >
                <img src={hand} />
                <p>Artists receive full payments no middlemen, no fees.</p>
              </div>
            </div>
          </div>
          <div className={styles.group_purchase_container_details_bottom}>
            <div className={styles.group_purchase_container_details_bottom_top}>
              <div>
                <button onClick={handleMoreOptions}>
                  {" "}
                  <span>{t("Group purchase")}</span>{" "}
                  <span>Rs. {videoDetails?.Prices}</span>{" "}
                </button>
                <button onClick={() => window.history.back()}>
                  <span> {t("More purchase")}</span> <span>{t("options")}</span>
                </button>
              </div>
              <div></div>
            </div>
            <div
              className={styles.group_purchase_container_details_bottom_bottom}
            >
              <p>
                *{" "}
                {t("The purchase include 5 days to start watching this video.")}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <GorupPurchaseSkeleton />
      )}

      {/* suggested for you   */}
      {suggestedVideoLoading && <CarouselSkeleton />}
      {!suggestedVideoLoading && suggestedData[category_slug]?.length >= 1 && (
        <ListCarousel
          title={t("More like this")}
          data={suggestedData[category_slug]}
        />
      )}
    </div>
  );
};

export default GroupPurchase;
