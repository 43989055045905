import React from "react";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.Footer_container}>
      <div className={styles.Footer_container_box}>
        <div className={styles.Footer_container_box_body}>
          <div className={styles.Footer_container_box_body_links}>
            <div className={styles.Footer_container_box_body_links_div}>
              <div>
                <NavLink to="/support">{t("FAQ")}</NavLink>
              </div>
              <div>
                <NavLink to="/legal-notices"> {t("Legal Notices")}</NavLink>
              </div>
            </div>
            <div className={styles.Footer_container_box_body_links_div}>
              <div>
                <NavLink to="/support">{t("Help Centre")}</NavLink>
              </div>
              <div>
                <NavLink to="/terms-of-use">{t("Terms of use")}</NavLink>
              </div>
            </div>
            <div className={styles.Footer_container_box_body_links_div}>
              <div>
                <NavLink to="/privacy">{t("Privacy")}</NavLink>
              </div>
              <div>
                <NavLink to="/contact-us">{t("Contact Us")}</NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.Footer_container_box_body_socials}>
              <h6>{t("Connect With Us")}</h6>
              <div className={styles.Footer_container_box_body_socials_link}>
                <NavLink
                  to="https://www.instagram.com/hall__iv/"
                  target="blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_102_27)">
                      <path
                        d="M23.9966 6.97995C23.9955 6.23788 23.9202 5.49926 23.7019 4.78947C23.4686 4.03126 23.1159 3.32684 22.6279 2.69237C21.9169 1.76891 21.0358 1.05989 19.9717 0.592199C19.4337 0.355473 18.8639 0.202908 18.2794 0.11452C18.0557 0.080702 17.8328 0.0384295 17.61 0H6.36911C6.22654 0.0249792 6.08436 0.0549542 5.94102 0.0745533C4.76898 0.235573 3.6907 0.617178 2.73615 1.34273C1.78084 2.06828 1.04956 2.96176 0.571908 4.05816C0.356713 4.55274 0.190321 5.06616 0.117308 5.60494C0.0808021 5.8728 0.0396845 6.13988 0.000488281 6.40735V17.5727C0.0135537 17.6111 0.036226 17.6488 0.0377631 17.6876C0.0854134 18.8885 0.464695 19.9853 1.12642 20.9771C1.97029 22.2422 3.10429 23.1461 4.55801 23.6368C5.09139 23.8171 5.63475 23.9301 6.19311 23.9777C6.42406 23.9973 6.65693 23.9988 6.88903 23.9988C10.3356 24 13.7818 24.0008 17.2284 23.9988C18.6525 23.9981 19.9598 23.5996 21.1219 22.7791C22.3197 21.9333 23.1724 20.8153 23.635 19.4138C23.8053 18.8981 23.9286 18.3735 23.9425 17.8267C23.9586 17.1972 23.9955 16.5681 23.997 15.9386C24.0028 12.9523 24.002 9.96631 23.9978 6.97995H23.9966ZM15.8996 16.2299C14.8297 17.218 13.5474 17.7321 12.0933 17.7606C8.81503 17.824 6.22654 15.1285 6.25421 12.0177C6.24575 8.80459 8.79351 6.48613 11.4638 6.29437C13.3418 6.15948 14.9569 6.75014 16.2466 8.13476C17.2641 9.22769 17.7621 10.5577 17.751 12.0357C17.7383 13.6805 17.1189 15.1039 15.8999 16.2299H15.8996ZM18.1072 7.21207C17.4574 7.18862 16.7872 6.55876 16.8049 5.76635C16.8095 4.98354 17.4082 4.33677 18.1795 4.34484C19.1271 4.35483 19.6624 4.97163 19.667 5.78095C19.6716 6.59643 19.0376 7.2455 18.1068 7.21168L18.1072 7.21207Z"
                        fill="white"
                      />
                      <path
                        d="M11.5978 8.20661C9.85359 8.33727 8.14817 9.90289 8.17238 12.03C8.13818 14.0087 9.84859 15.9859 12.2426 15.8452C13.9922 15.7426 16.0151 14.2043 15.8106 11.6422C15.6792 9.99551 14.1944 8.01216 11.5982 8.20661H11.5978Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_102_27">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </NavLink>

                <NavLink
                  to="https://www.linkedin.com/company/hall-iv/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0,0,256,256"
                    width="30"
                    height="30"
                  >
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                    >
                      <g transform="scale(5.33333,5.33333)">
                        <path
                          d="M42,37c0,2.762 -2.238,5 -5,5h-26c-2.761,0 -5,-2.238 -5,-5v-26c0,-2.762 2.239,-5 5,-5h26c2.762,0 5,2.238 5,5z"
                          fill="#ffffff"
                        ></path>
                        <path
                          d="M12,19h5v17h-5zM14.485,17h-0.028c-1.492,0 -2.457,-1.112 -2.457,-2.501c0,-1.419 0.995,-2.499 2.514,-2.499c1.521,0 2.458,1.08 2.486,2.499c0,1.388 -0.965,2.501 -2.515,2.501zM36,36h-5v-9.099c0,-2.198 -1.225,-3.698 -3.192,-3.698c-1.501,0 -2.313,1.012 -2.707,1.99c-0.144,0.35 -0.101,1.318 -0.101,1.807v9h-5v-17h5v2.616c0.721,-1.116 1.85,-2.616 4.738,-2.616c3.578,0 6.261,2.25 6.261,7.274l0.001,9.726z"
                          fill="#000000"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.Footer_container_box_footer}>
          <div className={styles.footer_copyright_text}>
            {t(
              "© 2025 Boxoffice Technologies Private Limited, All Rights Reserved."
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
