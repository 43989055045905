import React, { useEffect, useState } from "react";
import styles from "./WatchHistory.module.css";
import API from "../../utils/apiService";
import WatchHistoryList from "../../components/watchHistoryList/WatchHistoryList";
import { useTranslation } from "react-i18next";

const WatchHistory = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [contentList, setContentList] = useState(null);
  const getMovieRatingList = async () => {
    setLoading(true);

    try {
      let response = await API.get("getRatingMovieList");
      if (response?.status) {
        setContentList(response?.data);
      } else {
        setContentList(null);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMovieRatingList();
  }, []);

  return (
    <div className={styles.watch_history_container}>
      <h5>{t("Watch Histroy")}</h5>
      <div
        className={
          styles.watch_history_container_box +
          ` ${
            !contentList || contentList?.length <= 0
              ? styles.custome_center
              : ""
          }`
        }
      >
        {!contentList || contentList?.length <= 0 ? (
          <div className={styles.watch_history_container_not_found}>
            <label>{t("Result Not Found")}</label>
          </div>
        ) : (
          <WatchHistoryList data={contentList} />
        )}
      </div>
    </div>
  );
};

export default WatchHistory;
