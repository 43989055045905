import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import DetailsScreenBanner from "../../components/DetailsScreenBanner/DetailsScreenBanner";
import DescriptionDetails from "../../components/DescriptionDetails/DescriptionDetails";
import SelectSeason from "./Series/SelectSeason/SelectSeason";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../utils/apiService";
import { getDeviceType } from "../../utils/getDeviceType";
import { getVideoDetailsForDetailsScreen } from "../../utils/getVideoDetailsForDetailsScreen";
import Episodes from "./Series/Episodes/Episodes";
import DetailsScreenSkeleton from "../../components/Skeletons/DetailsScreenSkeleton";
import { checkPaymentStatusForSeasonOrMovies } from "../../utils/checkContentPurchaseStatus";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { seasonNumberFromRedux } = useSelector((store) => store.dataReducer);
  const { t } = useTranslation();
  const { video_id } = useParams();
  const [videoDetails, setVideoDetails] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalSeasons, setTotalSeasons] = useState(1);
  const [seasonNumber, setSeasonNumber] = useState(seasonNumberFromRedux || 1);
  const [episodeNumber, setEpisodeNumber] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isSeries, setIsSeries] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [category_slug, setCategory_slug] = useState("all");
  const [suggestedVideoLoading, setSuggestedVideoLoading] = useState(true);
  const { trendingData, continueWatchingData, whatsNewData, suggestedData } =
    useSelector((state) => state?.homeReducer);

  const getVideoDetailsById = async () => {
    setLoading(true);
    try {
      let response = await API.get(`getVideoDetailsById/${video_id}`);

      if (response?.status) {
        setVideoData(response?.data);
        let priceTypeForIndividuals;
        let priceTypeForGroups;
        let priceTypeForIndividualsEpisodes;
        let priceTypeForGroupsEpisodes;
        await getVideoPaymentDetailsById(response?.data?.isSeries);
        if (response?.data?.isSeries) {
          setTotalSeasons(response?.data?.seasons?.length);
          if (seasonNumber && episodeNumber) {
            priceTypeForGroups = "watchPartySeason";
            priceTypeForGroupsEpisodes = "watchPartyPerEpisode";
            priceTypeForIndividuals = "ppeSeason";
            priceTypeForIndividualsEpisodes = "ppePerEpisode";
          } else {
            priceTypeForGroups = "watchPartySeason";
            priceTypeForGroupsEpisodes = "watchPartyPerEpisode";
            priceTypeForIndividuals = "ppeSeason";
            priceTypeForIndividualsEpisodes = "ppePerEpisode";
          }
        } else {
          setTotalSeasons(1);
          priceTypeForGroups = "watchParty";
          priceTypeForIndividuals = "ppe";
        }
        setIsSeries(response?.data?.isSeries);

        let details = getVideoDetailsForDetailsScreen(
          response?.data?.isSeries,
          seasonNumber,
          episodeNumber,
          response?.data,
          priceTypeForIndividuals,
          priceTypeForIndividualsEpisodes,
          priceTypeForGroups,
          priceTypeForGroupsEpisodes,
          getDeviceType()
        );
        console.log("data", details);

        setVideoDetails(details);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getVideoPaymentDetailsById = async (isSeries) => {
    try {
      let url =
        "getPaymentStatusForVideo_v3" +
        `?videoId=${video_id}&isSeries=${isSeries ? "true" : "false"}`;
      if (isSeries) {
        url =
          "getPaymentStatusForVideo_v3" +
          `?videoId=${video_id}&isSeries=${
            isSeries ? "true" : "false"
          }&seasonNumber=${seasonNumber}`;
      }

      let response = await API.get(url);

      if (response?.status) {
        setPaymentStatus(response?.data);
        setPaymentDetails(
          checkPaymentStatusForSeasonOrMovies(
            response?.data,
            isSeries,
            seasonNumber,
            episodeNumber
          )
        );
      } else {
        setPaymentStatus(null);
        setPaymentDetails(null);
      }
    } catch (error) {
      setPaymentStatus(null);
      setPaymentDetails(null);
    } finally {
    }
  };

  useEffect(() => {
    getVideoDetailsById();
  }, []);

  useEffect(() => {
    if (isSeries) {
      let priceTypeForIndividuals;
      let priceTypeForGroups;
      let priceTypeForIndividualsEpisodes;
      let priceTypeForGroupsEpisodes;
      if (isSeries) {
        if (seasonNumber && episodeNumber) {
          priceTypeForGroups = "watchPartySeason";
          priceTypeForGroupsEpisodes = "watchPartyPerEpisode";
          priceTypeForIndividuals = "ppeSeason";
          priceTypeForIndividualsEpisodes = "ppePerEpisode";
        } else {
          priceTypeForGroups = "watchPartySeason";
          priceTypeForGroupsEpisodes = "watchPartyPerEpisode";
          priceTypeForIndividuals = "ppeSeason";
          priceTypeForIndividualsEpisodes = "ppePerEpisode";
        }
      } else {
        setTotalSeasons(1);
        priceTypeForGroups = "watchParty";
        priceTypeForIndividuals = "ppe";
      }
      let details = getVideoDetailsForDetailsScreen(
        isSeries,
        seasonNumber,
        episodeNumber,
        videoData,
        priceTypeForIndividuals,
        priceTypeForIndividualsEpisodes,
        priceTypeForGroups,
        priceTypeForGroupsEpisodes,
        getDeviceType()
      );
      setVideoDetails(details);
    }
    getVideoPaymentDetailsById(isSeries);
  }, [seasonNumber]);

  return (
    <div className={styles.details_container}>
      {loading ? (
        <DetailsScreenSkeleton />
      ) : (
        <>
          <DetailsScreenBanner
            {...videoDetails}
            isSeries={isSeries}
            episodeNumber={episodeNumber}
            seasonNumber={seasonNumber}
            paymentDetails={paymentDetails}
            video_id={video_id}
          />
          <DescriptionDetails {...videoDetails} />
          {isSeries && (
            <SelectSeason
              seasonsNumber={seasonNumber}
              setSeasonsNumber={setSeasonNumber}
              totalSeason={totalSeasons}
            />
          )}
          <Episodes {...videoDetails} paymentStatus={paymentStatus} />
        </>
      )}
    </div>
  );
};

export default Details;
