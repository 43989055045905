import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import API from "../../utils/apiService";
import { getWalletSucc } from "../../redux/dataReducer/action";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ButtonLoader } from "../../components/ButtonLoaders/ButtonLoader";
import Spinner from "react-bootstrap/Spinner";
import { Col, Modal, Row } from "react-bootstrap";
import DeleteAccountModal from "../../components/modals/DeleteAccountModal";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(null);
  const { walletBalance } = useSelector((store) => store.dataReducer);
  const [fullName, setFullName] = useState(
    localStorage.getItem("fullName") || ""
  );
  const [amount, setAmount] = useState(100);

  // get user details
  const getUserDetails = async () => {
    try {
      let response = await API.get("getUserDetails");
      if (response?.status) {
        setUserDetails(response?.data);
        localStorage.setItem("fullName", response?.data?.fullName);
        setFullName(response?.data?.fullName);
        localStorage.setItem("viewerId", response?.data?._id);
      }
    } catch (error) {}
  };

  // get wallet Detials
  const getWalletDetails = async () => {
    try {
      let response = await API.get("walletDetails");
      if (response?.status) {
        dispatch(getWalletSucc(response?.data?.accountBalance));
      }
    } catch (error) {}
  };

  // recharge wallet
  const handlePayClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        // the amount must be send in paise only
        amount: amount,
        transactionDate: new Date(),
      };
      let response = await API.post("pay_page", data);
      if (response?.status) {
        if (
          response?.data.success == true &&
          response?.data.code == "PAYMENT_INITIATED"
        ) {
          sessionStorage.setItem(
            "merchantTransactionId",
            response?.data.data.merchantTransactionId
          );
          window.location.href =
            response?.data.data.instrumentResponse.redirectInfo.url;
        }
      }
    } catch (error) {
      console.log(" the error in the handle pay click is : ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserDetails();
    getWalletDetails();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={styles.profile_container}>
        <div className={styles.profile_container_wallet}>
          <div className={styles.profile_container_wallet_header}>
            <label>{t("My Wallet")}</label>
            <h4>{t("Wallet Recharge")}</h4>
          </div>
          <form onSubmit={handlePayClick}>
            <div className={styles.profile_container_wallet_body}>
              <div className={styles.profile_container_wallet_body_top}>
                <input
                  type="number"
                  required
                  min={100}
                  className="no-spin no-scroll"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <p>
                  {t(
                    "Minimum recharge amount is Rs 100. Please select the amount in multiples of 100  i.e 200, 300, 400  etc."
                  )}
                </p>
              </div>
              <div className={styles.profile_container_wallet_body_bottom}>
                <div
                  className={styles.profile_container_wallet_body_bottom_btn}
                >
                  <button
                    className={
                      amount == 200
                        ? styles.profile_container_wallet_body_bottom_btn_button_selected
                        : ""
                    }
                    type="button"
                    onClick={() => setAmount(200)}
                  >
                    {t("200")}
                  </button>
                  {/* <label>{t("Bonus +50")}</label> */}
                </div>
                <div
                  className={styles.profile_container_wallet_body_bottom_btn}
                >
                  <button
                    className={
                      amount == 400
                        ? styles.profile_container_wallet_body_bottom_btn_button_selected
                        : ""
                    }
                    type="button"
                    onClick={() => setAmount(400)}
                  >
                    {t("400")}
                  </button>
                  {/* <label>{t("Bonus +100")}</label> */}
                </div>
                <div
                  className={styles.profile_container_wallet_body_bottom_btn}
                >
                  <button
                    className={
                      amount == 600
                        ? styles.profile_container_wallet_body_bottom_btn_button_selected
                        : ""
                    }
                    type="button"
                    onClick={() => setAmount(600)}
                  >
                    {t("600")}
                  </button>
                  {/* <label>{t("Bonus +150")}</label> */}
                </div>
                <div
                  className={styles.profile_container_wallet_body_bottom_btn}
                >
                  <button
                    className={
                      amount == 1000
                        ? styles.profile_container_wallet_body_bottom_btn_button_selected
                        : ""
                    }
                    type="button"
                    onClick={() => setAmount(1000)}
                  >
                    {t("1000")}
                  </button>
                  {/* <label>{t("Bonus +200")}</label> */}
                </div>
              </div>
            </div>
            <div className={styles.profile_container_wallet_btns}>
              <Button type="submit" disabled={loading}>
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <>
                    {" "}
                    {"Proceed to payment"}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="40"
                      viewBox="0 0 57 56"
                      fill="none"
                    >
                      <path
                        d="M23.75 39.6673L35.625 28.0007L23.75 16.334"
                        stroke="white"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </>
                )}
              </Button>
            </div>
          </form>
        </div>
        <div className={styles.profile_container_details}>
          <div className={styles.profile_container_details_top}>
            <h4>{t("Profile Details")}</h4>
            <div className={styles.profile_container_details_top_info}>
              <label>
                {t("Full Name")} : <span>{fullName}</span>
              </label>
              <label>
                {t("Email")} : <span>{userDetails && userDetails?.email}</span>
              </label>
            </div>
          </div>
          <hr />
          <div className={styles.profile_container_details_bottom}>
            <h4>{"Wallet Balance"}</h4>
            <div className={styles.profile_container_details_bottom_box}>
              <label>
                {t("Available Bal.")} Rs.{walletBalance || 0.0}
              </label>
            </div>
            <div className={styles.helper_text}>
              <p>
                {t(
                  "* Updating balance may take time. Please reload the page to see changes."
                )}
              </p>
            </div>

            <div className={styles.profile_container_details_bottom_btns}>
              <Button
                onClick={handleShow}
                className={styles.profile_container_details_bottom_btns_btn}
              >
                {t("Delete Account")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountModal show={show} handleClose={handleClose} />
    </>
  );
};

export default Profile;
