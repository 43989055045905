import React, { useEffect, useState } from "react";
import styles from "./Layout.module.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Outlet } from "react-router-dom";
import API from "../../utils/apiService";

const Layout = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [fullName, setFullName] = useState(
    localStorage.getItem("fullName") || ""
  );
  const getUserDetails = async () => {
    try {
      let response = await API.get("getUserDetails");
      if (response?.status) {
        setUserDetails(response?.data);
        localStorage.setItem("fullName", response?.data?.fullName);
        setFullName(response?.data?.fullName);
        localStorage.setItem("viewerId", response?.data?._id);
      }
    } catch (error) {}
  };

  const getFavouritesList = async () => {
    try {
      let response = await API.get("/favourite/get");
      if (response?.status) {
        let videoIds = response?.data?.map((el) => el?.videoId);
        console.log("videoIds: ", videoIds);
        localStorage.setItem("favourites", JSON.stringify(videoIds));
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getUserDetails();
    getFavouritesList();
  }, []);

  return (
    <div className={styles.layout_container}>
      <div className={styles.layout_container_header}>
        <Header />
      </div>
      <div className={styles.layout_container_outlet}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
