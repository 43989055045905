import React from "react";
import styles from "./Welcome.module.css";
import { useTranslation } from "react-i18next";
import LaptopLogo from "../../assets/svgs/LaptopLogo.svg";
import TabletLogo from "../../assets/svgs/TabletLogo.svg";
import MobileLogo from "../../assets/svgs/PhoneLogo.svg";
import HallivLogo from "../../assets/svgs/HallIVLogo.svg";
import variousDeviceList from "../../fixtures/variousDevices.json";
import SmartTVLogo from "../../assets/svgs/SmartTVLogo.svg";
import FAQItemsForWelcome from "../../components/faqItms/FAQItemsForWelcome";
import { NavLink } from "react-router-dom";

export const Welcome = () => {
  const { t } = useTranslation();

  const getDeviceLogo = (deviceName) => {
    switch (deviceName) {
      case "SmartTVLogo":
        return SmartTVLogo;
      case "TabletLogo":
        return TabletLogo;
      case "MobileLogo":
        return MobileLogo;
      default:
        return LaptopLogo; // Default logo if device name doesn't match
    }
  };

  return (
    <div className={styles.landing_screen}>
      <section className={styles.section_header}>
        <div className={styles.section_header_top}>
          <div className={styles.section_header_top_logo}>
            <img src={HallivLogo} />
          </div>
          <div className={styles.section_header_top_btn}>
            <NavLink to={"/signin"}>{t("Sign In")}</NavLink>
          </div>
        </div>
        <div className={styles.section_header_body}>
          <h1>{t("Welcome to Hall IV")}</h1>
          <div>
            <h2>{t("A Theatre From The Comfort Of Home")}</h2>
            <h5>
              {t(
                "From feature-length blockbusters to short films and documentaries, comedy to podcasts, we’re a streaming service that’s got you covered. There’s no subscription involved - just pay for what you want and get out the popcorn!"
              )}
            </h5>
            <br />
            <NavLink to={"/signup"}>{t("Get Started")}</NavLink>
          </div>
        </div>
      </section>
      <section className={styles.section_various_devices}>
        <div className={styles.section_various_devices_header}>
          <h5>
            {t("We Provide you streaming experience across various devices.")}
          </h5>
          <label>
            {t(
              "With Hall IV, you can enjoy your favourite movies and TV shows anytime, anywhere. Our platform is designed to be compatible with a wide range of devices, ensuring that you never miss a moment of entertainment."
            )}
          </label>
        </div>
        <div className={styles.section_various_devices_body}>
          {variousDeviceList?.map((item, index) => (
            <div
              key={item?.id}
              className={styles.section_various_devices_body_card}
            >
              <div className={styles.section_various_devices_body_card_header}>
                <div
                  className={
                    styles.section_various_devices_body_card_header_img
                  }
                >
                  <img src={getDeviceLogo(item.logo)} />
                </div>
                <h6>{item?.header}</h6>
              </div>
              <div className={styles.section_various_devices_body_card_body}>
                <p> {item.body}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.section_faqs}>
        <div className={styles.section_faqs_body}>
          <div className={styles.section_faqs_header}>
            <div className={styles.section_faqs_header_left}>
              <h5>{t("Frequently Asked Questions")}</h5>
              <label>
                {t(
                  "Got questions? We've got answers! Check out our FAQ section to find answers to the most common questions about Hall IV."
                )}
              </label>
            </div>
            <div className={styles.section_faqs_header_right}>
              <NavLink to={"/contact-us"}>{t("Ask a Question")}</NavLink>
            </div>
          </div>
          <FAQItemsForWelcome />
        </div>
      </section>
    </div>
  );
};
