import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import * as ROUTES from "./constants/routes";
import "fa-icons";
import { getToast } from "./utils/getToast";
import { getDeviceType } from "./utils/getDeviceType";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RedirectProvider } from "./helpers/redirect_route";
import ScrollToTop from "./utils/ScrollToTop";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import { ToastContainer, toast } from "react-toastify";
import AuthLayout from "./pages/auth/AuthLayout";
import { Welcome } from "./pages/welcome/Welcome";
import Login from "./pages/auth/login/Login";
import SignUp from "./pages/auth/signup/SignUp";
import OtpScreen from "./pages/auth/otpScreen/OtpScreen";
import Layout from "./pages/Layout/Layout";
import Home from "./pages/home/Home";
import TransactionHistory from "./pages/transectionHistory/TransactionHistory";
import Profile from "./pages/profile/Profile";
import Support from "./pages/support/Support";
import GroupPurchase from "./pages/group purchase/GroupPurchase";
import GroupPurchasePayment from "./pages/group purchase payment/GroupPurchasePayment";
import Search from "./pages/search/Search";
import Favourites from "./pages/favourites/Favourites";
import WatchHistory from "./pages/watch history/WatchHistory";
import Rating from "./pages/rating/Rating";
import InviteUsers from "./pages/invite users/InviteUsers";
import PaymentPending from "./pages/payment/paymentPending";
import Payment from "./pages/payment/payment";
import PaymentError from "./pages/payment/paymentError";
import PaymentSuccess from "./pages/payment/paymentSuccess";
import Privacy from "./pages/privacy/Privacy";
import TermAndConditons from "./pages/termAndConditons/TermAndConditons";
import LegalNotices from "./pages/legal notices/LegalNotices";
import ContactUs from "./pages/contact us/ContactUs";
import Details from "./pages/details/Details";
import DeleteAccountOTP from "./pages/DeleteAccount/DeleteAccountOTP";

export default function App() {
  const [isToastVisible, setIsToastVisible] = useState(false);

  return (
    <div className="App_Container">
      <Router>
        <ScrollToTop />
        <GoogleOAuthProvider clientId="892576251217-pii15lc8ep6p145roa5r7q1k9rjrjl9g.apps.googleusercontent.com">
          <RedirectProvider>
            <Routes>
              <Route element={<AuthLayout />}>
                <Route
                  path={ROUTES.SIGN_IN}
                  // element={<IsUserRedirect redirectPath={ROUTES.PRELAUNCH}><Signin /></IsUserRedirect>}
                  element={
                    <IsUserRedirect redirectPath={"/browse/all"}>
                      <Login getDeviceType={getDeviceType} />
                      {/* <Signin /> */}
                    </IsUserRedirect>
                  }
                />
                <Route
                  path={ROUTES.FORGOT_PASSWORD}
                  // element={<ForgotPassword getDeviceType={getDeviceType} />}
                  getToast={getToast}
                />
                <Route
                  path={ROUTES.CHANGE_PASSWORD}
                  // element={<ChangePassword getDeviceType={getDeviceType} />}
                  getToast={getToast}
                />
                <Route
                  path={ROUTES.SIGN_UP}
                  // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH}><Signup /></IsUserRedirect>}
                  element={
                    <IsUserRedirect redirectPath={"/browse/all"}>
                      <SignUp getDeviceType={getDeviceType} />
                      {/* <Signup /> */}
                    </IsUserRedirect>
                  }
                />
                <Route
                  path={ROUTES.LOGIN_WITH_GOOGLE}
                  // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH}><LoginWithGoogle /></IsUserRedirect>}
                  element={
                    <IsUserRedirect redirectPath={"/browse/all"}>
                      {/* <LoginWithGoogle getDeviceType={getDeviceType} /> */}
                    </IsUserRedirect>
                  }
                />
                <Route
                  path={ROUTES.OTP}
                  element={<OtpScreen getDeviceType={getDeviceType} />}
                />
                <Route
                  path={ROUTES.Welcome}
                  // element={<IsUserRedirect  redirectPath={ROUTES.PRELAUNCH} exact><Home /></IsUserRedirect>}
                  element={
                    <IsUserRedirect redirectPath={"/browse/all"} exact>
                      <Welcome getDeviceType={getDeviceType} />
                    </IsUserRedirect>
                  }
                />

                <Route
                  path={"/account-deletion"}
                  element={<DeleteAccountOTP />}
                />
              </Route>

              <Route path={ROUTES.LEGAL_NOTICES} element={<LegalNotices />} />

              <Route
                path={ROUTES.TERMS_OF_USE}
                element={<TermAndConditons />}
              />
              <Route path={ROUTES.PRIVACY} element={<Privacy />} />

              <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />

              <Route element={<Layout />}>
                {/* browse */}
                <Route
                  index
                  path={ROUTES.BROWSE}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                {/* delete account */}
                <Route
                  path={"/profile/delete-account"}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      {/* <DeleteAccount
                        getDeviceType={getDeviceType}
                        getToast={getToast}
                      /> */}
                    </ProtectedRoute>
                  }
                />
                {/* search */}
                <Route
                  index
                  path={ROUTES.SEARCH}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Search />
                    </ProtectedRoute>
                  }
                />
                {/* description page */}
                <Route
                  index
                  path={ROUTES.DESCRIPTION}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Details />
                    </ProtectedRoute>
                  }
                />

                {/* Group Purchase Page */}
                <Route
                  index
                  path={ROUTES.GROUP_PURCHASE}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <GroupPurchase />
                    </ProtectedRoute>
                  }
                />

                {/* Group Purchase Payment Page */}
                <Route
                  index
                  path={ROUTES.GROUP_PURCHASE_PAYMENT}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <GroupPurchasePayment />
                    </ProtectedRoute>
                  }
                />

                {/* prelaunch */}
                <Route
                  index
                  path={ROUTES.PRELAUNCH}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      {/* <PreLaunchPage getDeviceType={getDeviceType} /> */}
                    </ProtectedRoute>
                  }
                />
                {/* invite user */}
                <Route
                  path={ROUTES.INVITE_USERS}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <InviteUsers />{" "}
                    </ProtectedRoute>
                  }
                />

                {/* profile */}
                <Route
                  index
                  path={ROUTES.PROFILE}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                {/* favourite */}
                <Route
                  index
                  path={ROUTES.FAVOURITES}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Favourites />
                    </ProtectedRoute>
                  }
                />
                {/* order history */}
                <Route
                  index
                  path={ROUTES.ORDER_HISTORY}
                  element={
                    // <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                    <TransactionHistory />
                    // </ProtectedRoute>
                  }
                />
                {/* buy tickets */}
                <Route
                  index
                  path={ROUTES.BUY_TICKET}
                  //   element={
                  //     <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                  //     <BuyTickets getDeviceType={getDeviceType} />
                  //    </ProtectedRoute>
                  //   }
                />
                <Route
                  path={ROUTES.PAYEMENT}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Payment />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.PAYMENT_PENDING}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <PaymentPending />
                    </ProtectedRoute>
                  }
                />

                <Route
                  index
                  path={ROUTES.PAYMENT_ERROR}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <PaymentError />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.RATING}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Rating />
                    </ProtectedRoute>
                  }
                />
                {/* watch histroy */}
                <Route
                  path={ROUTES.WATCH_HISTORY}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <WatchHistory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.SUPPORT}
                  element={
                    <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                      <Support />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path={ROUTES.PAYMENT_SUCCESS}
                element={
                  <ProtectedRoute redirectPath={ROUTES.SIGN_IN}>
                    <PaymentSuccess />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </RedirectProvider>
        </GoogleOAuthProvider>
      </Router>
      <ToastContainer stacked />
    </div>
  );
}
