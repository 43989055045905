import React, { useState } from "react";
import styles from "./FAQItems.module.css";
import faqData from "../../fixtures/faqs.json";

const FAQItems = () => {
  const [activeKey, setActiveKey] = useState(null);

  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className={styles.faqContainer}>
      <div className={styles.faqGrid}>
        {/* Left Column */}
        <div className={styles.faqColumn}>
          {faqData.slice(0, 2).map((faq) => (
            <div className={styles.faqColumnBox}>
              <div key={faq.id} className={styles.faqCard}>
                <div
                  className={styles.faqHeader}
                  onClick={() => toggleAccordion(faq.id)}
                >
                  <div className={styles.faqId}>{faq.id}</div>
                  <div className={styles.faqQuestion}>{faq.header}</div>
                  <span className={styles.faqToggle}>
                    {activeKey === faq.id ? "−" : "+"}
                  </span>
                </div>
                <div
                  className={`${styles.faqBody} ${
                    activeKey === faq.id ? styles.open : ""
                  }`}
                >
                  <div className={styles.faqBodyContent}>{faq.body}</div>
                </div>
              </div>
              <span className={styles.span_box}></span>
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className={styles.faqColumn}>
          {faqData.slice(2).map((faq) => (
            <div className={styles.faqColumnBox}>
              <div key={faq.id} className={styles.faqCard}>
                <div
                  className={styles.faqHeader}
                  onClick={() => toggleAccordion(faq.id)}
                >
                  <div className={styles.faqId}>{faq.id}</div>
                  <div className={styles.faqQuestion}>{faq.header}</div>
                  <span className={styles.faqToggle}>
                    {activeKey === faq.id ? "−" : "+"}
                  </span>
                </div>
                <div
                  className={`${styles.faqBody} ${
                    activeKey === faq.id ? styles.open : ""
                  }`}
                >
                  <div className={styles.faqBodyContent}>{faq.body}</div>
                </div>
              </div>
              <span className={styles.span_box}></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQItems;
