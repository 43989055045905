import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { movieBaseUrl } from "../../Api-config/movie.js";
import { MobilePpePlayer } from "../mediaPlayer/MobilePpePlayer.jsx";
import { MediaPlayer } from "../mediaPlayer/PpeMediaPlayer.jsx";
import { WatchPartyMediaPlayer } from "../mediaPlayer/WatchPartyMediaPlayer.jsx";
import { FullScreenLoader } from "../../components/fullScreen loader/FullScreenLoader.jsx";
import { generateTokenHandler } from "../../utils/generateTokenHandler.js";
import { getAudioDataSuccess } from "../../redux/sinkReducer/action.js";
import { getDeviceType } from "../../utils/getDeviceType.js";
import { getToast } from "../../utils/getToast.js";
import TicketModal from "../../components/ticket modal/TicketModal.jsx";
import { groupPurchaseDetails } from "../../utils/groupPurchaseDetails.js";
// import { parseCustomJsonString } from "../../utils/parseCustomJsonString.js";
import { clearPreviousHistory } from "../../utils/deletePreviousRoutes.js";

export default function PaymentSuccess() {
  const [isSeries, setIsSeries] = useState(false);
  const [movieTitle, setMovieTitle] = useState("");
  const [viewers, setViewers] = useState(() => {
    const storedName = localStorage.getItem("fullName");
    return storedName ? [storedName] : []; // Ensure it initializes as an array
  });
  const [playFromPaymentHandler, setPlayFromPaymentHandler] = useState(
    JSON.parse(localStorage.getItem("playFromPaymentHandler")) == true || false
  );

  // const [playFromPaymentHandler, setPlayFromPaymentHandler] = useState(true);

  // const [showTicketModal, setShowTicketModal] = useState(true);

  const [showTicketModal, setShowTicketModal] = useState(
    JSON.parse(localStorage.getItem("playFromPaymentHandler")) == true || false
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [authToken, setAuthToken] = useState(null);
  const [playerSrc, setPlayerSrc] = useState(null);
  const [assetId, setAssestId] = useState("");
  const [transactionId, setTransactionId] = useState("");

  // save episodesList here
  const [episodesList, setEpisodesList] = useState([]);
  const viewerId = window.viewerId || localStorage.getItem("viewerId");
  const location = JSON.parse(localStorage.getItem("location")) || {
    latitude: "20.59",
    longitude: "78.96",
  };
  console.log("viewerId: ", viewerId);
  const paymentDetails = parseCustomJsonString(
    sessionStorage.getItem("paymentDetails") || ""
  );
  // console.log("paymentDetails:", paymentDetails.toString());
  const episodeNumber = sessionStorage.getItem("episodeNumber");
  // episode selected
  const [selectedEpisode, setSelectedEpisode] = useState(
    parseInt(episodeNumber) ? parseInt(episodeNumber) : 1
  );
  const videoId = window.videoDetails ?? sessionStorage.getItem("videoDetails");

  const [widevineLicenseURI, setWideWineLicenseURI] = useState("");
  console.log("videoId: ", videoId);
  // token
  const token = localStorage.getItem("authUser");
  console.log("token: ", token);
  // getting device information
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);
  const primaryDevice = window.primaryDevice;
  // this way we will get the viewership Id
  const viewerShipId =
    window.viewerShipId || sessionStorage.getItem("viewerShipId");
  const [counter, setCounter] = useState(0);
  const deviceDetailsFromApp = parseCustomJsonString(
    window.deviceDetails || localStorage.getItem("deviceDetails")
  );
  console.log("deviceDetails:", deviceDetailsFromApp);
  const videoRef = useRef(null);

  const { audioUrl, subTitle } = useSelector((store) => store.sinkReducer);
  const dispatch = useDispatch();
  const startTime = new Date().getTime() / 1000; // Convert to seconds

  const [seasonNumber, setSeasonNumber] = useState(null);
  const isPPe =
    sessionStorage.getItem("watchType") == "ppe" ||
    sessionStorage.getItem("watchType") == "ppePerEpisode" ||
    sessionStorage.getItem("watchType") == "ppeSeason";
  // getToast(t("Payemtn Si" + isPPe), "top-right", "warn");
  // Custom function to parse non-standard JSON-like string

  function parseCustomJsonString(inputString = "") {
    if (!inputString) {
      return;
    }

    var modifiedString = inputString.substring(1, inputString.length - 1);
    var keyValuePairs = modifiedString.split(",");
    var result = {};

    keyValuePairs.forEach(function (pair) {
      var parts = pair.split(/:\s*/);
      var key = parts[0]?.trim()?.replace(/"|'/g, "");
      var value = parts[1]?.trim().replace(/"|}/g, "");

      result[key] = value;
      console.log("result[key] = value;: ", result[key], value);
    });

    return result;
  }

  // see where the usser need to redirect ppe Screen or watchParty

  console.log("isPPe:  ", isPPe);

  const getAssetDetailsById = async (data) => {
    console.log("getAssetIdDetailsById --------");
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
      slug: slug,
    };
    console.log(token);
    console.log(headers?.devicetype);
    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }
    console.log(headers.appid);
    // code for fetching details via assetsId

    const config = {
      method: "post",
      url: movieBaseUrl + "getAssetIdByVideoId",
      headers: headers,
      data,
    };
    axios
      .request(config)
      .then((res) => {
        // set the asset Id here
        if (res.data.status) {
          const assetId = res?.data?.data?.assetId;
          console.log("assetId: ", assetId);
          console.log(assetId);
          sessionStorage.setItem("assetId", assetId);
          setAssestId(assetId);
          // generateAuthToken(assetId);
          setTransactionId(res?.data?.data?.transactionId);
          const audioFileUrl = res?.data?.data?.audioUrl[0].file;
          const subTitleFileUrl =
            res?.data?.data?.subTitle && res.data.data?.subTitle[0]?.file;
          // send details to save in reducer

          const reducerDetails = {
            audioUrl: audioFileUrl,
            viewerShipId,
            assetId,
            viewerId,
            subTitle: subTitleFileUrl,
          };
          dispatch(getAudioDataSuccess(reducerDetails));
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  // get all the episode list
  const getEpisodes = async () => {
    let videoId = window.videoDetails ?? sessionStorage.getItem("videoDetails");
    console.log("getE");
    setCounter(counter + 1);
    let slug = await generateTokenHandler();
    let headers = {
      authorization: token,
      slug: slug,
      devicetype: deviceDetailsFromApp?.deviceType ?? getDeviceType(),
    };
    console.log("devicetype: ", headers?.devicetype);
    console.log("authorization: ", headers.authorization);
    console.log("deviceId", deviceDetailsFromApp?.deviceId);

    if (deviceDetailsFromApp && deviceDetailsFromApp?.deviceId) {
      headers.appid = deviceDetailsFromApp?.deviceId;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: movieBaseUrl + `getVideoDetailsById/${videoId}`,
      headers: headers,
    };
    axios
      .request(config)
      .then((res) => {
        console.log("detailed data", res?.data?.data);
        if (res?.data?.data) {
          const data = res?.data?.data;
          setIsSeries(data?.isSeries);
          sessionStorage.setItem("isSeries", data?.isSeries);

          var assetsData = {
            videoId: videoId,
          };
          if (data?.isSeries) {
            const currentSeason = sessionStorage.getItem("seasonNumber") || 1;
            const season = data?.seasons?.find(
              (item) => item?.seasonNumber === currentSeason
            );
            setSeasonNumber(season?.seasonNumber);
            assetsData.seasonNumber = season?.seasonNumber;
            assetsData.episodeNumber = selectedEpisode;
            setEpisodesList(season?.episodes);
            getAssetDetailsById(assetsData);
            let { title } = groupPurchaseDetails(
              data?.isSeries,
              currentSeason,
              selectedEpisode,
              data
            );
            setMovieTitle(title || "");
          } else {
            getAssetDetailsById(assetsData);
            let { title } = groupPurchaseDetails(data?.isSeries, 1, null, data);
            setMovieTitle(title || "");
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.response_code == 401) {
          navigate("/signin");
          localStorage.clear();
          getToast(
            t("Your token is expired. Please login again."),
            "top-right",
            "error"
          );
        } else {
          getToast(err?.response?.data?.message, "top-right", "error");
        }
      });
  };

  // handle selcted epissode
  const handleSelectEpisode = (episodeNumber) => {
    setSelectedEpisode(episodeNumber);
    sessionStorage.setItem("episodeNumber", episodeNumber);

    // call the get AssetDetials here
  };

  // const checkStatusAPI = async () => {
  //   const config = {
  //     method: "post",
  //     url: movieBaseUrl + "check_status",
  //     headers: {
  //       Authorization: token,
  //     },
  //     data: {
  //       transactionId: sessionStorage.getItem("transactionId"),
  //     },
  //   };
  //   const checkStatusResponse = await axios(config);
  //   console.log(
  //     "the value of the check status api response is : ",
  //     checkStatusResponse
  //   );
  //   if (
  //     checkStatusResponse.data.status &&
  //     checkStatusResponse.data.data.status
  //   ) {
  //     const responseStatus = checkStatusResponse.data.data.data;
  //     // here we have to handle to send the user to different pages according to the codes like -> PAYMENT_DECLINED , TIMED_OUT , BAD_REQUEST , AUTHORIZATION_FAILED , TRANSACTION_NOT_FOUND , etc
  //     if (
  //       responseStatus.success == true &&
  //       responseStatus.code == "PAYMENT_SUCCESS"
  //     ) {
  //       // navigate("/paymentSuccess");
  //       return;
  //     } else if (
  //       responseStatus.success == false &&
  //       responseStatus.code == "PAYMENT_ERROR"
  //     ) {
  //       navigate("/paymentError");
  //       return;
  //     } else if (
  //       responseStatus.success == false &&
  //       (responseStatus.code == "BAD_REQUEST" ||
  //         responseStatus.code == "AUTHORIZATION_FAILED" ||
  //         responseStatus.code == "TRANSACTION_NOT_FOUND")
  //     ) {
  //       console.log("BAD REQUEST ");
  //       navigate("/paymentError");
  //       return;
  //     }
  //   }
  //   // console.log("This is the checkStatus API calling..............");

  //   const currentTime = new Date().getTime() / 1000; // Convert to seconds
  //   // console.log(
  //   //   ">>>>>>>>>>>>>>>the time interval for every call is : ",
  //   //   currentTime - startTime
  //   // );

  //   if (currentTime - startTime < 50) {
  //     callApiWithInterval(3000); // Every 3 seconds for the next 30 seconds
  //   } else if (currentTime - startTime < 110) {
  //     callApiWithInterval(6000); // Every 6 seconds for the next 60 seconds
  //   } else if (currentTime - startTime < 170) {
  //     callApiWithInterval(10000); // Every 10 seconds for the next 60 seconds
  //   } else if (currentTime - startTime < 230) {
  //     callApiWithInterval(30000); // Every 30 seconds for the next 60 seconds
  //   } else if (currentTime - startTime < 1355) {
  //     callApiWithInterval(60000); // Every 1 min until timeout (20 mins)
  //   }
  // };

  // const callApiWithInterval = (interval) => {
  //   setTimeout(() => {
  //     checkStatusAPI();
  //   }, interval);
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!deviceDetailsFromApp && !deviceDetailsFromApp.deviceId) {
  //       // return;
  //       checkStatusAPI();
  //     }
  //   }, 20000);
  // }, []);

  useEffect(() => {
    getEpisodes();
    // navigate(-1);
  }, [selectedEpisode, navigate]);

  const handleCloseModal = () => {
    setShowTicketModal(false);
    localStorage.setItem("playFromPaymentHandler", false);
  };

  useEffect(() => {
    if (playFromPaymentHandler) {
      // clearPreviousHistory(2);
      // localStorage.setItem("playFromPaymentHandler", false);
    }
  }, []);
  return (
    <div>
      {playFromPaymentHandler && (
        <TicketModal
          movieTitle={movieTitle}
          show={showTicketModal}
          handleClose={handleCloseModal}
          viewers={viewers}
        />
      )}

      {assetId ? (
        paymentDetails?.payDevice == "mobile" && isPPe ? (
          <MobilePpePlayer
            getToast={getToast}
            asset_id={assetId}
            handleSelectEpisode={handleSelectEpisode}
            getDeviceType={getDeviceType}
          />
        ) : isPPe ? (
          <MediaPlayer
            getToast={getToast}
            asset_id={assetId}
            handleSelectEpisode={handleSelectEpisode}
            getDeviceType={getDeviceType}
          />
        ) : (
          <WatchPartyMediaPlayer
            getToast={getToast}
            asset_id={assetId}
            handleSelectEpisode={handleSelectEpisode}
            getDeviceType={getDeviceType}
          />
        )
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <FullScreenLoader />
        </div>
      )}
    </div>
  );
}
