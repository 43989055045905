import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import API from "../../utils/apiService";
import FavoriteList from "../../components/favoritesList/FavoriteList";
import styles from "./Favourites.module.css";
import { getToast } from "../../utils/getToast";

const Favourites = () => {
  const [contentList, setContentList] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getFavouritesList = async () => {
    setLoading(true);
    try {
      let response = await API.get("/favourite/get");
      if (response?.status) {
        setContentList(response?.data);
        let videoIds = response?.data?.map((el) => el?.videoId);
        console.log("videoIds: ", videoIds);
        localStorage.setItem("favourites", JSON.stringify(videoIds));
      } else {
        setContentList(null);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // handling delete function
  const handleDeleteFavourite = async (e, id) => {
    e.stopPropagation();
    setDeleteLoading(true);
    try {
      let data = {
        videoId: id,
      };
      let response = await API.delete("/favourite/remove", data);
      if (response?.status) {
        getToast(response?.message, "top-right", "success");
        getFavouritesList();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    getFavouritesList();
  }, []);

  return (
    <div className={styles.favourrites_container}>
      <h5>{t("Your Favourites")}</h5>

      <div className={styles.favourrites_container_box}>
        {!contentList || contentList?.length <= 0 ? (
          <div className={styles.favourrites_container_not_found}>
            <label>
              {t("No favorites yet? Discover some content you'll love")}
            </label>
          </div>
        ) : (
          <FavoriteList
            data={contentList}
            deleteLoading={deleteLoading}
            handleDeleteFavourite={(e, id) => handleDeleteFavourite(e, id)}
          />
        )}
      </div>
    </div>
  );
};

export default Favourites;
