import React, { useEffect, useState } from "react";
import styles from "./Episodes.module.css";
import EpisodeCard from "./components/EpisodeCard";
import { durationConverter } from "../../../../utils/durationConverter";

const Episodes = ({
  title,
  synopsis,
  cbfc_Rating,
  PricesIndividuals,
  PricesGroupsEpisodes,
  PricesIndividualsEpisodes,
  PricesGroups,
  trailers,
  isAvod,
  backgroundImage,
  duration,
  genre,
  episodes,
  seasonNumber,
  paymentStatus,
  currentSeason,
}) => {
  const [deviceType, setDeviceType] = useState("laptop");
  const [visibleEpisodes, setVisibleEpisodes] = useState(10);

  const loadMoreEpisodes = () => {
    setVisibleEpisodes((prevVisibleEpisodes) => prevVisibleEpisodes + 10);
  };
  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (isAtBottom) {
      // Call your function when the window is fully scrolled
      loadMoreEpisodes();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth <= 480) {
        setDeviceType("mobile");
      } else if (window.innerWidth >= 481 && window.innerWidth <= 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("laptop");
      }
    };

    // Ensure that the device type is updated on mount as well
    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  return (
    <div>
      <div className={styles.episodesContainer}>
        {episodes
          ?.sort((a, b) => a.episodeNumber - b.episodeNumber) // Sort episodes by episode number
          .slice(0, visibleEpisodes) // Show only the number of episodes defined in visibleEpisodes
          .map((episode, index) => (
            <EpisodeCard
              key={episode.id || index} // Unique key for each episode
              cbfc_Rating={cbfc_Rating}
              imageSrc={episode.thumbnail?.file} // Episode thumbnail image
              title={`S${seasonNumber} E${episode.episodeNumber} - ${episode.title}`} // Episode title with season and episode number
              duration={durationConverter(episode.duration)} // Formatted duration of the episode
              episodeNumber={episode?.episodeNumber} // Episode number
              seasonNumber={seasonNumber} // Season number
              description={episode.synopsis} // Episode synopsis
              cast={episode?.cast} // Episode cast details
              crew={episode?.crew} // Episode crew details
              isFreeEpisode // Boolean flag for free episodes
              paymentStatus={paymentStatus} // Payment status for episode access
              deviceType={deviceType}
              currentSeason={currentSeason}
              PricesIndividuals={PricesIndividualsEpisodes}
              PricesGroups={PricesGroupsEpisodes}
            />
          ))}
      </div>
    </div>
  );
};

export default Episodes;
