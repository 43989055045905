import React from "react";
import styles from "./DetailsScreenSkeleton.module.css";
import { Placeholder } from "react-bootstrap";

const DetailsScreenSkeleton = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <Placeholder animation="glow" className="mb-3">
        <Placeholder
          xs={12}
          size="lg"
          className={styles.details_screen_Placeholder} // Use CSS Module class
        />
      </Placeholder>
    </div>
  );
};

export default DetailsScreenSkeleton;
