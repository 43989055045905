import styles from "./Carousel.Btns.module.css";
import LeftArrow from "../../../assets/svgs/LeftArrow.svg";
import RightArrow from "../../../assets/svgs/RightArrow.svg";

export const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`${""} ${styles.slick_prev}`}>
      <img src={LeftArrow} />
    </div>
  );
};

export const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`${""} ${styles.slick_next}`}>
      <img src={RightArrow} />
    </div>
  );
};
