import React from "react";

export const handleClickTicket = ({
  isSeries,
  episodeNumber,
  seasonNumber,
  navigate,
}) => {
  console.log("isSeries.........", isSeries);
  console.log("seasonNumber........", seasonNumber);
  console.log("episodeNumber......", episodeNumber);

  if (isSeries) {
    sessionStorage.setItem("seasonNumber", seasonNumber);
    sessionStorage.setItem("episodeNumber", episodeNumber || null);
  } else {
    console.log("else");
  }

  navigate("group-purchase");
};
