import React from "react";
import styles from "./ResumePlay.module.css";
import { useTranslation } from "react-i18next";
import { handleResumeWatching } from "../../utils/handleResumeWatching";
import { useNavigate } from "react-router-dom";

const ResumePlay = ({
  videoId,
  episodeNumber,
  seasonNumber,
  isSeries,
  transactionId,
  viewerShipId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCallResume = () => {
    handleResumeWatching({
      videoId,
      episodeNumber,
      seasonNumber,
      isSeries,
      transactionId,
      viewerShipId,
      navigate,
    });
  };
  return (
    <div className={styles.resume_play_container}>
      <button
        onClick={handleCallResume}
        className={styles.resume_play_container_btn}
      >
        {t("Resume Play")}
      </button>
    </div>
  );
};

export default ResumePlay;
