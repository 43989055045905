export const Welcome = "/";
export const BROWSE = "/browse/:category_slug";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LOGIN_WITH_GOOGLE = "/login-with-google";
export const LEGAL_NOTICES = "/legal-notices";
export const CONTACT_US = "/contact-us";
export const PRELAUNCH = "/pre-launch";
export const INVITE_USERS = "/invite-users";
export const DESCRIPTION = "/details/:video_id";
export const GROUP_PURCHASE = "/details/:video_id/group-purchase";
export const GROUP_PURCHASE_PAYMENT =
  "/details/:video_id/group-purchase-payment";
export const PROFILE = "/profile";
export const ORDER_HISTORY = "/orderhistory";
export const BUY_TICKET = "/buy_tickets/:step_slug";
export const SEARCH = "/browse";
export const PAYEMENT = "/payment";
export const PAYMENT_SUCCESS = "/paymentSuccess";
export const PAYMENT_ERROR = "/paymentError";
export const PAYMENT_PENDING = "/paymentPending";
export const FAVOURITES = "/favourites";
export const TERMS_OF_USE = "/terms-of-use";
export const PRIVACY = "/privacy";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHANGE_PASSWORD = "/change-password";
export const RATING = "/rating-and-reviews";
export const WATCH_HISTORY = "/watch-history";
export const SUPPORT = "/support";
export const OTP = "/otp";
