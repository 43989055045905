import React from "react";

export const getDeviceType = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log("userAgent: ", userAgent);

  if (window.innerWidth >= 1800) {
    return "tv";
  } else if (
    userAgent.includes("windows") ||
    userAgent.includes("win64") ||
    userAgent.includes("win32") ||
    // 768×1024
    userAgent.includes("macos") ||
    window.innerHeight >= 1024 ||
    window.innerWidth >= 768
  ) {
    return "laptop";
  } else {
    return "mobile";
  }
};
