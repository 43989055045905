import React, { useState } from "react";
import styles from "./FAQItemsForWelcome.module.css";
import faqData from "../../fixtures/faqs.json";

const FAQItemsForWelcome = () => {
  const [activeKey, setActiveKey] = useState(null);

  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className={styles.wlcFaqContainer}>
      <div className={styles.wlcFaqGrid}>
        {/* Left Column */}
        <div className={styles.wlcFaqColumn}>
          {faqData.slice(0, 2).map((wlcFaq) => (
            <div className={styles.wlcFaqColumnBox}>
              <div key={wlcFaq.id} className={styles.wlcFaqCard}>
                <div
                  className={
                    styles.wlcFaqHeader +
                    ` ${
                      activeKey === wlcFaq.id
                        ? styles.wlcFaqHeader_background
                        : ""
                    }`
                  }
                  onClick={() => toggleAccordion(wlcFaq.id)}
                >
                  <div className={styles.wlcFaqId}>{wlcFaq.id}</div>
                  <div className={styles.wlcFaqQuestion}>{wlcFaq.header}</div>
                  <span className={styles.wlcFaqToggle}>
                    {activeKey === wlcFaq.id ? "−" : "+"}
                  </span>
                </div>
                <div
                  className={`${styles.wlcFaqBody} ${
                    activeKey === wlcFaq.id ? styles.open : ""
                  }`}
                >
                  <div className={styles.wlcFaqBodyContent}>{wlcFaq.body}</div>
                </div>
              </div>
              <span className={styles.span_box}></span>
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className={styles.wlcFaqColumn}>
          {faqData.slice(2).map((wlcFaq) => (
            <div className={styles.wlcFaqColumnBox}>
              <div key={wlcFaq.id} className={styles.wlcFaqCard}>
                <div
                  className={
                    styles.wlcFaqHeader +
                    ` ${
                      activeKey === wlcFaq.id
                        ? styles.wlcFaqHeader_background
                        : ""
                    }`
                  }
                  onClick={() => toggleAccordion(wlcFaq.id)}
                >
                  <div className={styles.wlcFaqId}>{wlcFaq.id}</div>
                  <div className={styles.wlcFaqQuestion}>{wlcFaq.header}</div>
                  <span className={styles.wlcFaqToggle}>
                    {activeKey === wlcFaq.id ? "−" : "+"}
                  </span>
                </div>
                <div
                  className={`${styles.wlcFaqBody} ${
                    activeKey === wlcFaq.id ? styles.open : ""
                  }`}
                >
                  <div className={styles.wlcFaqBodyContent}>{wlcFaq.body}</div>
                </div>
              </div>
              <span className={styles.span_box}></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQItemsForWelcome;
