import React from "react";
import { useNavigate } from "react-router-dom";
import { getDeviceType } from "./getDeviceType";

export const handleResumeWatching = ({
  videoId,
  episodeNumber,
  seasonNumber,
  paymentDetails,
  isSeries,
  navigate,
  transactionId,
  viewerShipId,
}) => {
  console.log("videoId", transactionId);

  sessionStorage.setItem("videoDetails", videoId);
  sessionStorage.setItem("viewerShipId", viewerShipId);
  sessionStorage.setItem("transactionId", transactionId);
  localStorage.setItem("playFromPaymentHandler", false);
  if (isSeries) {
    sessionStorage.setItem("seasonNumber", seasonNumber);
    sessionStorage.setItem("episodeNumber", episodeNumber || 1);
  }
  localStorage.setItem("playWithAds", false);
  localStorage.setItem(
    "deviceDetails",
    JSON.stringify({
      deviceType: getDeviceType(),
    })
  );
  // sessionStorage.setItem("watchType", "watchParty");
  sessionStorage.setItem(
    "watchType",
    isSeries ? "watchPartySeason" : "watchParty"
  );

  navigate("/paymentSuccess");
};
