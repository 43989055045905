import React from "react";
import styles from "./DescriptionDetails.module.css";
import { useTranslation } from "react-i18next";

const DescriptionDetails = ({
  title,
  synopsis,
  cbfc_Rating,
  PricesIndividuals,
  PricesGroups,
  trailers,
  isAvod,
  backgroundImage,
  duration,
  genre,
  crew,
  cast,
  director,
  languages,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.descriptionDetails_container}>
      <div className={styles.descriptionDetails_container_left}>
        <div className={styles.descriptionDetails_container_left_description}>
          <h6>Description</h6>
          <p>{synopsis || ""}</p>
        </div>

        <div className={styles.descriptionDetails_container_left_body}>
          <div className={styles.descriptionDetails_container_left_body_cast}>
            <h6>{t("Cast")}</h6>
            <div>
              {cast?.length > 0 &&
                cast?.map((item, index) => (
                  <label key={index}>{item?.name}</label>
                ))}
            </div>
          </div>
          <div className={styles.descriptionDetails_container_left_body_crow}>
            <h6>{t("Crew")}</h6>
            <div>
              {crew?.length > 0 &&
                crew?.map((item, index) => (
                  <label key={index}>{item?.name}</label>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.descriptionDetails_container_right}>
        <div className={styles.descriptionDetails_container_right_languages}>
          <div
            className={
              styles.descriptionDetails_container_right_languages_header
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.5 21.5L15.75 10.25L21 21.5M12 18.5H19.5M3 6.12136C4.96557 5.87626 6.96804 5.75 9 5.75M9 5.75C10.1208 5.75 11.2326 5.78841 12.3343 5.864M9 5.75V3.5M12.3343 5.864C11.1763 11.1578 7.68868 15.5801 3 18.0023M12.3343 5.864C13.2298 5.92545 14.1186 6.01146 15 6.12136M10.4113 14.6162C8.78554 12.9619 7.47704 10.9949 6.58432 8.81366"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h6>{t("Available Languages")}</h6>
          </div>
          <div
            className={styles.descriptionDetails_container_right_languages_body}
          >
            {languages?.length > 0 &&
              languages?.map((item, index) => (
                <label key={index}>{item?.name}</label>
              ))}
          </div>
        </div>
        {/* <div className={styles.descriptionDetails_container_right_Ratings}>
          <div
            className={styles.descriptionDetails_container_right_Ratings_header}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M11.4807 3.99883C11.6729 3.53685 12.3273 3.53685 12.5195 3.99883L14.6454 9.11028C14.7264 9.30504 14.9096 9.43811 15.1199 9.45497L20.6381 9.89736C21.1369 9.93735 21.3391 10.5598 20.9591 10.8853L16.7548 14.4867C16.5946 14.6239 16.5246 14.8392 16.5736 15.0444L17.858 20.4293C17.9741 20.916 17.4447 21.3007 17.0177 21.0398L12.2933 18.1542C12.1133 18.0443 11.8869 18.0443 11.7069 18.1542L6.98251 21.0398C6.55551 21.3007 6.02606 20.916 6.14215 20.4293L7.42664 15.0444C7.47558 14.8392 7.40562 14.6239 7.24543 14.4867L3.04111 10.8853C2.66112 10.5598 2.86335 9.93735 3.36209 9.89736L8.88034 9.45497C9.0906 9.43811 9.27375 9.30504 9.35476 9.11028L11.4807 3.99883Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h6>{t("Ratings")}</h6>
          </div>
          <div
            className={styles.descriptionDetails_container_right_Ratings_body}
          ></div>
        </div> */}

        <div className={styles.descriptionDetails_container_right_Gernes}>
          <div
            className={styles.descriptionDetails_container_right_Gernes_header}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3.75 6.5C3.75 5.25736 4.75736 4.25 6 4.25H8.25C9.49264 4.25 10.5 5.25736 10.5 6.5V8.75C10.5 9.99264 9.49264 11 8.25 11H6C4.75736 11 3.75 9.99264 3.75 8.75V6.5Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.75 16.25C3.75 15.0074 4.75736 14 6 14H8.25C9.49264 14 10.5 15.0074 10.5 16.25V18.5C10.5 19.7426 9.49264 20.75 8.25 20.75H6C4.75736 20.75 3.75 19.7426 3.75 18.5V16.25Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5 6.5C13.5 5.25736 14.5074 4.25 15.75 4.25H18C19.2426 4.25 20.25 5.25736 20.25 6.5V8.75C20.25 9.99264 19.2426 11 18 11H15.75C14.5074 11 13.5 9.99264 13.5 8.75V6.5Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5 16.25C13.5 15.0074 14.5074 14 15.75 14H18C19.2426 14 20.25 15.0074 20.25 16.25V18.5C20.25 19.7426 19.2426 20.75 18 20.75H15.75C14.5074 20.75 13.5 19.7426 13.5 18.5V16.25Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h6>{t("Genres")}</h6>
          </div>

          <div
            className={styles.descriptionDetails_container_right_Gernes_body}
          >
            {genre?.length > 0 &&
              genre?.map((item, index) => <label key={index}>{item}</label>)}
          </div>
        </div>

        <div className={styles.descriptionDetails_container_right_Director}>
          <div
            className={
              styles.descriptionDetails_container_right_Director_header
            }
          >
            <h6>{t("Director")}</h6>
          </div>
          <div
            className={styles.descriptionDetails_container_right_Director_body}
          >
            <label>{director || ""}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionDetails;
