import React, { useEffect, useState } from "react";
import styles from "./HomeBanner.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BannerSkeleton } from "../Skeletons/BannerSkeleton";
import { Carousel } from "react-bootstrap";
import { getSeasonNumber } from "../../redux/dataReducer/action";
import API from "../../utils/apiService";
import {
  getHomeDataBannerError,
  getHomeDataBannerSuccess,
} from "../../redux/homeReducer/action";

const HomeBanner = ({ category_slug }) => {
  const dispatch = useDispatch();
  const { bannerData } = useSelector((state) => state?.homeReducer);
  const { t } = useTranslation();
  const [banners, setBanners] = useState([]);
  const [bannersLoading, setBannersLoading] = useState(true);
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTrailerAndVideoClick = async (
    videoId,
    isSeries,
    seasonNumber
  ) => {
    sessionStorage.setItem("banner", videoId);
    sessionStorage.setItem("isSeries", isSeries);
    dispatch(getSeasonNumber(1));

    try {
      let data;
      if (isSeries) {
        data = {
          videoId,
          trailer: false,
          seasonNumber: seasonNumber || 1,
          banner: true,
        };
      } else {
        data = {
          videoId,
          trailer: false,
          banner: true,
        };
      }

      API.post("videoClickOrTrailerPlayed", data);
    } catch (error) {}

    navigate(`/details/${videoId}`);
  };

  // Fetch banners
  const getBannerList = async () => {
    if (bannerData.length > 0) {
      setBannersLoading(false);
      return;
    }
    setBannersLoading(true);

    try {
      let response = await API.get("getBannerList");
      if (response?.status) {
        if (category_slug == "all") {
          setBannerList(response?.data?.slice(0, 4));
        } else {
          let data = response?.data
            ?.filter((item) => item?.category == category_slug)
            .slice(0, 4);
          if (data?.length >= 2) {
            setBannerList(data);
          } else {
            setBannerList(response?.data.slice(0, 4));
          }
        }
        setBanners(response?.data);
        dispatch(getHomeDataBannerSuccess(response?.data));
      } else {
        dispatch(getHomeDataBannerError());
      }
    } catch (error) {
      dispatch(getHomeDataBannerError());
    } finally {
      setBannersLoading(false);
    }
  };

  useEffect(() => {
    getBannerList();
  }, []);

  useEffect(() => {
    if (bannerData && bannerData.length >= 1) {
      setActiveIndex(0);
      setBannersLoading(true);

      let filteredData =
        category_slug === "all"
          ? bannerData
          : bannerData.filter(
              (item) =>
                item?.category ===
                (category_slug === "tvSeries" ? "series" : category_slug)
            );

      // Apply limit of 4 banners
      let limitedData = (
        filteredData.length >= 2 ? filteredData : bannerData
      ).slice(0, 4);

      setBannerList(limitedData);
      setBannersLoading(false);
    }
  }, [category_slug]);

  return (
    <div className={styles.carousel_container_box}>
      {bannersLoading && <BannerSkeleton />}
      {!bannersLoading && (
        <Carousel
          className={styles.carousel_container}
          indicators={true}
          controls={true}
          activeIndex={activeIndex} // Controlled active index
          onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
        >
          {bannerList?.map((el, i) => (
            <Carousel.Item key={i} className={styles.banner_box}>
              <div
                onClick={() =>
                  handleTrailerAndVideoClick(
                    el?.videoId,
                    el?.isSeries,
                    el?.seasonNumber
                  )
                }
                className={styles.banner_images}
                style={{
                  backgroundImage: `linear-gradient(0deg, #141414 0%, rgba(20, 20, 20, 0) 100%), url(${encodeURI(
                    el?.backgroundImage?.file
                  )})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              />
              <Carousel.Caption className={styles.banner_footer}>
                <h3 className={styles.banner_title}>{el?.title}</h3>
                <p className={styles.banner_synopsis}>{el?.synopsis}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default HomeBanner;
