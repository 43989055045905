import React from "react";
import styles from "./FavoriteList.module.css";
import movie_poster_icon from "../../assets/images/no_result_found.png";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const FavoriteItem = ({
  videoId,
  title,
  keyArt1,
  genre,
  rating,
  isSeries,
  seasons,
  handleDeleteFavourite,
  deleteLoading,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const handleClick = async (e) => {
    e.stopPropagation();
    e.preventDefault(); // Prevent default NavLink behavior

    console.log(e.target.tagName); // Debugging log

    if (
      e.target.tagName == "svg" ||
      e.target.tagName == "button" ||
      e.target.tagName == "BUTTON" ||
      e.target.tagName == "SVG"
    ) {
      setLoading(true);
      await handleDeleteFavourite(e, videoId);
      setLoading(false);
    } else {
      navigate(`/details/${videoId}`);
    }
  };

  return (
    <div className={styles.content_item}>
      <div className={styles.poster_container}>
        <img
          src={keyArt1?.file}
          onError={(e) => {
            e.target.src = movie_poster_icon;
          }}
        />
        <div className={styles.content_item_delete}>
          <button disabled={loading} onClick={(e) => handleClick(e)}>
            {loading ? (
              <Spinner as={"span"} size="sm" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
              </svg>
            )}
          </button>
        </div>
        {isSeries && seasons?.seasonNumber && (
          <div className={styles.carousel_item_chip}>
            <label>Season {seasons?.seasonNumber}</label>
          </div>
        )}
      </div>
      <div className={styles.content_item_header}>
        <h6>{title}</h6>
      </div>
    </div>
  );
};

export default FavoriteItem;
