import React, { useEffect, useState } from "react";
import styles from "./TransactionHistory.module.css";
import { useTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import walletIcon from "../../assets/svgs/walletIcon.svg";
import API from "../../utils/apiService";
import { convertTimeStamp } from "../../utils/convertTimeStamp";
import no_result from "../../assets/images/no_result_found.png";

const TransactionHistory = () => {
  const { t } = useTranslation();
  const [orderItems, setOrderItems] = useState(null);
  const [orderItemsCount, setOrderItemsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [seeAll, setSeeAll] = useState(false);
  const [perPage, setPerPage] = useState(5);

  const checkType = (el) => {
    if (el?.videoId == null && el?.ruleApplicationId == null) {
      return "Wallet Recharge";
    } else if (el?.videoId == null && el?.ruleApplicationId) {
      return "Bonus";
    } else if (el?.videoId && el?.walletTransaction) {
      return "Video Purchase (Wallet)";
    } else if (el?.videoId && el?.walletTransaction == false) {
      return "Video Purchase";
    } else {
      return "";
    }
  };

  const getOrderHistory = async () => {
    setLoading(true);

    try {
      let response = await API.get(
        `sendFinancialInfo?page=1&perPage=${perPage}`
      );
      if (response?.status) {
        const totalPage = response.data[0]?.totalCount[0]?.totalDocuments;
        setOrderItemsCount(totalPage);
        const orderItem = response.data[0]?.transactions;
        setOrderItems(orderItem);
      } else {
        setOrderItems(null);
        setOrderItemsCount(0);
      }
    } catch (error) {
      setOrderItems(null);
      setOrderItemsCount(0);
    } finally {
      setLoading(false);
    }
  };
  const handleSeeAll = () => {
    if (perPage >= 500) {
      setPerPage(5);
    } else {
      setPerPage(500);
    }
    setSeeAll((prev) => !prev);
  };

  useEffect(() => {
    getOrderHistory();
  }, [perPage]);

  return (
    <div className={styles.transaction_history_container}>
      <div className={styles.transaction_history_container_header}>
        <h5>{t("Transaction History")}</h5>
        {orderItemsCount >= 6 && (
          <label onClick={handleSeeAll}>
            {seeAll ? t("See less") : t("See all")}{" "}
          </label>
        )}
      </div>
      <div className={styles.transaction_history_container_body}>
        <div className={styles.transaction_history_container_body_header}>
          <label>{t("Content Type")}</label>
          <label>{t("Transaction Type")}</label>
          <label>{t("Amount")}</label>
        </div>
        {orderItems && orderItems?.length <= 0 ? (
          <div className={styles.transaction_history_container_body_not_found}>
            <img src={no_result} />
          </div>
        ) : (
          <div className={styles.transaction_history_container_body_container}>
            <ListGroup
              as="ol"
              className={
                styles.transaction_history_container_body_container_list
              }
            >
              {orderItems &&
                orderItems?.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className={
                      styles.transaction_history_container_body_container_list_item
                    }
                  >
                    <div className={styles.list_item}>
                      <div>
                        <img
                          src={
                            checkType(item) != "Wallet Recharge" &&
                            checkType(item) != "Bonus"
                              ? // checkType(el) != "Bonus"
                                item?.keyArt
                              : walletIcon
                          }
                        />
                      </div>
                      <div>
                        <h5> {checkType(item)}</h5>
                        <label>{convertTimeStamp(item?.transactionDate)}</label>
                      </div>
                      <div>
                        <label>Rs. {item?.amount}</label>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;
