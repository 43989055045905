import React, { useEffect, useState } from "react";
import styles from "./InviteUsers.module.css";
import { useTranslation } from "react-i18next";
import API from "../../utils/apiService";
import { getToast } from "../../utils/getToast";
import { Modal, Button, Spinner } from "react-bootstrap"; // Import Bootstrap components
import "./nviteUsers.css";

const InviteUsers = () => {
  const { t } = useTranslation();
  let [rc, setRc] = useState("");
  const [allReferredUser, setAllReferredUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [inputFields, setInputFields] = useState([
    { email: "", isValid: true },
  ]);
  const [emailModal, setEmailModal] = useState(false);

  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index].email = event.target.value;
    setInputFields(values);
  };
  const handleAddFields = () => {
    setInputFields([{ email: "", isValid: true }, ...inputFields]);
  };

  // Open/Close Email Modal
  const toggleEmailModal = () => setEmailModal(!emailModal);

  const sendInvite = async () => {
    // Regular Expression for Email Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if there is at least one email input and it's not empty
    if (inputFields.length === 1 && inputFields[0].email?.trim() === "") {
      setMsg("*Email is required");
      setTimeout(() => setMsg(""), 4000);
      return;
    }

    // Validate each email before sending
    let validEmails = [];
    let invalidEmails = [];

    inputFields.forEach((field) => {
      if (field.email?.trim() !== "") {
        if (emailRegex.test(field.email.trim())) {
          validEmails.push({ email: field.email, isValid: "succ" });
        } else {
          invalidEmails.push({ email: field.email, isValid: false });
        }
      }
    });

    // Update UI for invalid emails
    if (invalidEmails.length > 0) {
      setInputFields([...validEmails, ...invalidEmails]); // Keep both valid & invalid
      setMsg("*Please provide valid emails.");
      setTimeout(() => setMsg(""), 4000);
      return;
    }

    setEmailSendLoading(true);

    try {
      let data = { rc, emails: validEmails };
      let response = await API.postAuth("api/viewerApp/sendInvites", data);

      if (response?.status) {
        let invalidResponseEmails = response?.["Invalid-emails"] || [];

        // Update UI for invalid emails from API response
        const updatedFields = inputFields.map((field) => ({
          ...field,
          isValid: invalidResponseEmails.includes(field.email) ? false : "succ",
        }));
        setInputFields(updatedFields);

        if (invalidResponseEmails.length === inputFields.length) {
          setMsg("*Please provide valid emails");
          setTimeout(() => setMsg(""), 4000);
        } else if (invalidResponseEmails.length === 0) {
          getToast(t("Send Successfully"), "top-right", "success");
          setInputFields([{ email: "", isValid: true }]);
          toggleEmailModal();
          // setMsg("Sent successfully");
        } else {
          setMsg("*Red marked emails are invalid");
          setTimeout(() => setMsg(""), 4000);
        }
      }
    } catch (error) {
      setMsg("*An error occurred while sending invites");
      setTimeout(() => setMsg(""), 4000);
    } finally {
      setEmailSendLoading(false);
    }
  };

  const handleGetReferredUsers = async () => {
    const refCode = localStorage.getItem("referralCode");
    setRc(refCode);
    try {
      setLoading(true);
      let response = await API.getAuth(
        `api/viewerApp/getReferredUsers?rc=${refCode}`
      );

      if (response?.status) {
        setAllReferredUser(response?.data?.data || null);
      } else {
        setAllReferredUser(null);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.origin + `/signup?rc=${rc}`)
      .then(() => {
        console.log("Text copied to clipboard");
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  // Remove Email Input Field
  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleShareWhatsApp = () => {
    const message = `Join me on HallIV! Click here to sign up: ${window.location.origin}/signup?rc=${rc}`;
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  };

  useEffect(() => {
    handleGetReferredUsers();
  }, []);

  return (
    <div className={styles.invite_users_container}>
      <div className={styles.invite_users_container_header}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-gift"
          viewBox="0 0 16 16"
        >
          <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5z" />
        </svg>
        <h4>{t("Give people you love free Hall IV.")}</h4>
      </div>
      <hr />
      <div className={styles.invite_users_container_body}>
        <div className={styles.invite_users_container_body_header}>
          <h5>
            {t(
              "Share this link with friends or family and they'll start watching Hall IV, just like you."
            )}
          </h5>
        </div>
        <div className={styles.invite_users_container_body_box}>
          <div className={styles.invite_users_container_body_box_left}>
            <div className={styles.invite_users_container_body_box_left_div}>
              <input
                readOnly
                value={window.location.origin + `/signup?rc=${rc}`}
              />
              <button onClick={handleCopy}>{t("Copy Link")}</button>
              {tooltipVisible && (
                <span
                  className={
                    styles.invite_users_container_body_box_left_div_tooltip
                  }
                >
                  Copied
                </span>
              )}
            </div>
          </div>
          <h3>OR</h3>
          <div className={styles.invite_users_container_body_box_right}>
            <div>
              <div onClick={toggleEmailModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
                <label>{t("Email")}</label>
              </div>
              <div onClick={handleShareWhatsApp}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
                <label>{t("WhatsApp")}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.invite_users_container_steps}>
        <h4>{t("HOW IT WORKS")}</h4>
        <div className={styles.invite_users_container_steps_box}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-share"
              viewBox="0 0 16 16"
            >
              <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
            </svg>
            <div>
              <h6>{t("Step")} 1</h6>
              <p>
                {t(
                  "Share your link with friends by copying the link or choose an icon."
                )}
              </p>
            </div>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={
              "bi bi-arrow-down " +
              styles.invite_users_container_steps_box_down_arrow
            }
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={
              "bi bi-arrow-right " +
              styles.invite_users_container_steps_box_right_arrow
            }
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-person-plus"
              viewBox="0 0 16 16"
            >
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
              <path
                fill-rule="evenodd"
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
              />
            </svg>
            <div>
              <h6>{t("Step")} 2</h6>
              <p>{t("We'll let you know when a friend signs up.")}</p>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={
              "bi bi-arrow-down " +
              styles.invite_users_container_steps_box_down_arrow
            }
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={
              "bi bi-arrow-right " +
              styles.invite_users_container_steps_box_right_arrow
            }
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chat-square-text"
              viewBox="0 0 16 16"
            >
              <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
              <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
            </svg>
            <div>
              <h6>{t("Step")} 3</h6>
              <p>
                {t(
                  "You can share with as many friends and family as you like!"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {allReferredUser?.length > 0 && (
        <div className={styles.invite_users_container_invited_box}>
          <h4>{t("Invited Users")}</h4>
          <div className={styles.invite_users_container_invited_box_body}>
            {loading ? (
              <Spinner />
            ) : allReferredUser?.length ? (
              allReferredUser.map((user, index) => (
                <div
                  key={index}
                  className={
                    styles.invite_users_container_invited_box_body_user
                  }
                >
                  <div>
                    <h6>
                      {t("Full Name")} : {user?.name || ""}
                    </h6>
                    <label>
                      {t("Email")} : {user?.email || ""}
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <p>{t("No invited users yet")}</p>
            )}
          </div>
        </div>
      )}

      {/* <div className={styles.invite_users_container_invited_box}>
        <h4>{t("")}</h4>
        <div className={styles.invite_users_container_invited_box_body}></div>
      </div> */}

      {/* Email Invite Modal */}
      <Modal
        show={emailModal}
        onHide={toggleEmailModal}
        className={styles.boom_animation} // Apply animation from module CSS
        centered
      >
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title>{t("Invite via Email")}</Modal.Title>
          <span className={styles.closeIcon} onClick={toggleEmailModal}>
            &times;
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.boom_animation_div}>
            {inputFields.map((field, index) => (
              <div
                key={index}
                className={`d-flex align-items-center mb-2 ${styles.input_container}`}
              >
                <input
                  type="email"
                  placeholder="Enter email"
                  value={field.email}
                  onChange={(e) => handleChange(index, e)}
                  // className="form-control"
                />
                {inputFields.length > 1 && (
                  <button
                    className={`btn btn-primery ms-2 ${styles.remove_button}`}
                    onClick={() => handleRemoveFields(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>
                )}
              </div>
            ))}

            {msg !== "" && <span>{msg}</span>}

            <button
              className={`btn btn-secondary mt-2 ${styles.add_button}`}
              onClick={handleAddFields}
            >
              + Add More
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleEmailModal}>
            Close
          </Button>
          <Button
            disabled={emailSendLoading}
            variant="primary"
            onClick={sendInvite}
          >
            {emailSendLoading ? <Spinner /> : "Send Invites"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InviteUsers;
