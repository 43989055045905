import React from "react";
import styles from "./WatchHistoryList.module.css";
import movie_poster_icon from "../../assets/images/no_result_found.png";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WatchHistoryItem = ({
  videoId,
  title,
  keyArt1,
  genre,
  rating,
  isSeries,
  seasons,
  transactionIds = [],
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault(); // Prevent default NavLink behavior

    console.log(e.target.tagName); // Debugging log

    if (
      e.target.tagName == "svg" ||
      e.target.tagName == "button" ||
      e.target.tagName == "BUTTON" ||
      e.target.tagName == "SVG"
    ) {
      sessionStorage.setItem("videoRated", videoId);
      sessionStorage.setItem("transcRated", transactionIds[0]);
      navigate("/rating-and-reviews");
      console.log("btn clickes");
    } else {
      navigate(`/details/${videoId}`);
    }
  };

  return (
    <div className={styles.content_item}>
      <div className={styles.poster_container}>
        <img
          src={keyArt1?.file}
          onError={(e) => {
            e.target.src = movie_poster_icon;
          }}
        />
        <div className={styles.content_item_rating}>
          <button onClick={(e) => handleClick(e)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-star"
              viewBox="0 0 16 16"
            >
              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
            </svg>
          </button>
        </div>
        {isSeries && seasons?.seasonNumber && (
          <div className={styles.carousel_item_chip}>
            <label>Season {seasons?.seasonNumber}</label>
          </div>
        )}
      </div>
      <div className={styles.content_item_header}>
        <h6>{title}</h6>
      </div>
    </div>
  );
};

export default WatchHistoryItem;
